import React from 'react';
import { useLocation, Location } from 'react-router-dom';
import { Typography } from '@oqee/ui';

import Breadcrumb from '../../shared/Breadcrumb';
import ButtonBack from '../../shared/ButtonBack';

import './NavHeader.css';

interface NavHeaderProps {
  title?: string;
  onBack?: () => void;
  onSkip?: () => void;
}

const NavHeader: React.FC<NavHeaderProps> = ({ title, onBack, onSkip }) => {
  const location: Location = useLocation();
  const enableBack: boolean = location.key !== 'default';

  return (
    <div className="NavHeader">
      <Breadcrumb>{enableBack && <ButtonBack title={title} onClick={onBack} />}</Breadcrumb>
      {onSkip && (
        <div className="SkipLinkContainer" onClick={onSkip}>
          <Typography variant="body2">Passer</Typography>
        </div>
      )}
    </div>
  );
};

export default NavHeader;
