import React from 'react';
import { useParams } from 'react-router-dom';
import { usePageSingle } from '@oqee/core';

import MainLayout from '../../components/layout/MainLayout';
import SinglePage from '../../components/single/SinglePage';

type VodDetailPageParams = {
  portalId: string;
  contentId: string;
};

interface VodDetailPageProps {
  type: 'vod' | 'vod_portal';
}

const VodDetailPage: React.FC<VodDetailPageProps> = ({ type }) => {
  const { portalId, contentId } = useParams<VodDetailPageParams>();
  const { isReady, programEntry } = usePageSingle(type, contentId, Number(portalId));

  return (
    <MainLayout fbScreenName={`vod_${contentId}`} program={programEntry} withBackdrop>
      <SinglePage isReady={isReady} program={programEntry} />
    </MainLayout>
  );
};

export default VodDetailPage;
