import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useSvodService } from '@oqee/core';

import { HeaderNav } from '../../../../utils/webUtils';
import MainLayout from '../../../../components/layout/MainLayout';
import NavHeader from '../../../../components/layout/NavHeader';
import Spinner from '../../../../components/shared/Spinner';
import Typography from '../../../../components/shared/Typography';
import { ListSvod } from '../../../../components/shared/List';

import './SubscriptionsListSvod.css';

function SubscriptionsListSvod() {
  const navigate: NavigateFunction = useNavigate();
  const { services, loading, refreshSvod } = useSvodService();

  const handleBack = () => navigate('/settings');

  if (!services || loading) {
    return <Spinner />;
  }

  return (
    <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>
      <NavHeader onBack={handleBack} />
      <Typography variant="h2" className="SubscriptionsListTitleSvod">
        Mes abonnements
      </Typography>
      <div className="SubscriptionsListSvod">
        <ListSvod svodItems={services} refreshSvod={refreshSvod} />
      </div>
    </MainLayout>
  );
}

export default SubscriptionsListSvod;
