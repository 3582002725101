import React from 'react';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Typography, NpvrUsageProgress } from '@oqee/ui';
import { useNpvrQuota, useNpvrQuotaUsage } from '@oqee/core';

import { HeaderNav } from '../../../utils/webUtils';

import MainLayout from '../../../components/layout/MainLayout';
import NavHeader from '../../../components/layout/NavHeader';

import './NpvrUsage.css';
import './NpvrUsageProgress.css';

function NpvrUsage() {
  const navigate: NavigateFunction = useNavigate();
  const quota = useNpvrQuota();
  const usage = useNpvrQuotaUsage(quota);

  const { title, description, footer } = usage;

  const handleBack = () => navigate(-1);

  return (
    <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>
      <NavHeader onBack={handleBack} title="Retour" />
      <div className="NpvrUsageSection">
        <div className="NpvrUsageSectionDetails">
          <div className="NpvrUsageSectionTitle">
            <Typography variant="h1">{title}</Typography>
          </div>
          <div className="NpvrUsageSectionPrice">
            <Typography variant="h4">{description}</Typography>
          </div>
          <div>
            <NpvrUsageProgress
              totalDuration={quota.totalDuration / 60 / 60}
              freeUsageQuota={quota.freeUsageQuota / 60 / 60}
              maxUsageQuota={quota.maxUsageQuota / 60 / 60}
            />
          </div>
          <div className="NpvrUsageSectionActions">
            <Typography variant="body3">{footer}</Typography>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default NpvrUsage;
