import { CONSTANTS, argb2rgba } from '@oqee/core';
import React from 'react';

import Button from '../Button';
import Typography from '../Typography';
import useWebError from '../../../hooks/useWebError';
import ModalOpaqueBackground from '../ModalOpaqueBackground';

import Breadcrumb from '../Breadcrumb';
import ButtonBack from '../ButtonBack';

import './InputCodeModal.css';

const BAD_CONFIRMATION_CODE = 'bad_confirmation_code';

export interface InputCodeModalProps {
  title: string;
  subtitle: string;
  showLostCodeTip: boolean;
  codeAttemptLeft: number | null;
  codeLocked: boolean;
  onValidate: (code: string) => void;
  onCancel: () => void;
  onClick?: () => void;
  onStep?: (value: number) => void;
  cgv?: string | false;
  infoProvider?: React.ReactNode;
  stepFooter?: React.ReactNode;
}

function InputCodeModal({
  title,
  subtitle,
  showLostCodeTip,
  codeAttemptLeft = null,
  codeLocked,
  onValidate,
  onCancel,
  onStep,
  onClick,
  cgv,
  infoProvider,
  stepFooter
}: InputCodeModalProps) {
  const [codeArray, setCodeArray] = React.useState<Array<string | null>>([null, null, null, null]);
  const error = useWebError();

  const handleKeyDown = React.useCallback(
    (evt: KeyboardEvent) => {
      const firstNullIndex: number = codeArray.indexOf(null);
      if (evt.key >= '0' && evt.key <= '9') {
        // digit input
        if (firstNullIndex > -1) {
          // append digit to code value
          const newCodeArray: Array<string | null> = codeArray.map((e, idx) => (idx === firstNullIndex ? evt.key : e));
          setCodeArray(newCodeArray);

          if (firstNullIndex === codeArray.length - 1) {
            // code complete : validate
            const fourDigitsCode: string = newCodeArray.join('');
            onValidate(fourDigitsCode);
          }
        }
      } else if (evt.key === 'Backspace') {
        // backspace input
        if (firstNullIndex === -1) {
          setCodeArray(codeArray.map((e, idx) => (idx === codeArray.length - 1 ? null : e)));
        } else if (firstNullIndex > 0) {
          setCodeArray(codeArray.map((e, idx) => (idx === firstNullIndex - 1 ? null : e)));
        }
      }
    },
    [codeArray]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  React.useEffect(() => {
    if (codeAttemptLeft !== null) {
      // reset code value after incorrect code input
      setCodeArray([null, null, null, null]);
    }
  }, [codeAttemptLeft]);

  React.useEffect(() => {
    if (error.code === BAD_CONFIRMATION_CODE) {
      // reset code value after bad confirmation code
      setCodeArray([null, null, null, null]);

      return () => error.reset();
    }
  }, [error]);

  React.useEffect(() => {
    // reset errors on unmount
    return () => error.reset();
  }, []);

  const onCancelPurchase = React.useCallback(() => {
    onCancel();
    onStep && onStep(0);
  }, []);

  return (
    <>
      <div className="InputCodeModalContainer">
        <div className="InputCodeModal">
          <div className="InputCodeModalTitle">
            <Typography variant="h4">{title}</Typography>
          </div>
          <div className="InputCodeModalContent">
            {onClick && <Breadcrumb>{<ButtonBack onClick={onClick} />}</Breadcrumb>}

            {infoProvider}

            <Typography variant="body1">{subtitle}</Typography>

            <div className="InputCodeContainer">
              <Typography variant="body2" className="InputCodeError">
                {error.code === BAD_CONFIRMATION_CODE ? (
                  <>{error.description}</>
                ) : codeLocked ? (
                  <>Limite atteinte, veuillez réessayer plus tard</>
                ) : codeAttemptLeft ? (
                  <>
                    Le code est incorrect. Il vous reste {codeAttemptLeft} essai
                    {codeAttemptLeft > 1 ? 's' : ''}.
                  </>
                ) : null}
              </Typography>

              <div className="InputCode">
                {[0, 1, 2, 3].map(index => (
                  <div key={index} className="InputCodeDigit">
                    <Typography variant="h2">{codeArray[index] ?? '-'}</Typography>
                  </div>
                ))}
              </div>
            </div>

            {cgv && (
              <Typography variant="body3" className="InputCgv">
                En poursuivant votre commande, vous acceptez les{' '}
                <a
                  href={cgv}
                  target="_blank"
                  rel="noreferrer"
                  className="InputUnderline"
                  style={{ color: argb2rgba('#FFBDBDBD') }}
                >
                  Conditions Générales de Ventes
                </a>{' '}
                et <br /> acceptez l’accès immédiat au service et renoncez à votre droit de rétractation.
              </Typography>
            )}

            <div className="InputCodeTip">
              {showLostCodeTip && (
                <Typography variant="body3">
                  Si vous ne trouvez plus votre code, merci de vous connecter à <br />
                  <a
                    href={CONSTANTS.PARENTAL_CODE_REMINDER_LINK}
                    target="_blank"
                    rel="noreferrer"
                    className="InputUnderline"
                  >
                    {CONSTANTS.PARENTAL_CODE_REMINDER_LINK}
                  </a>{' '}
                  et aller dans <strong>Télévision</strong> puis <strong>Gérer mes codes TV (achat et parental)</strong>
                </Typography>
              )}
            </div>
          </div>
          <div className={stepFooter ? 'VodInputCodeModalActions' : 'InputCodeModalActions'}>
            {stepFooter}
            <Button label="Annuler" type="primary" onClick={onCancelPurchase} />
          </div>
        </div>
      </div>
      <ModalOpaqueBackground />
    </>
  );
}

export default InputCodeModal;
