import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { APIEpgEntry, ChannelPlaybackInfo } from '@oqee/core';

import { useWebStoreState, webStore } from '../../../../store/webStoreUtils';
import { STARTOVER_MAX_DURATION_SEC, absoluteTimeToBrowserTime } from '../../../../utils/playerUtils';
import { BrowserContext, BrowserContextType } from '../../../context/BrowserContextProvider';

const GoToStartButton = class extends shaka.ui.Element {
  constructor(
    parent: HTMLDivElement,
    controls: shaka.ui.Controls,
    playbackInfo: ChannelPlaybackInfo,
    browserContext: BrowserContextType
  ) {
    super(parent, controls);
    const isStartOverAllowed = !!playbackInfo?.liveChannel?.isStartOverAllowed;
    const this_ = this as any;
    this_.button_ = document.createElement('button');
    this_.button_.id = 'oqee-goToStart-button';
    if (!isStartOverAllowed) {
      this_.button_.disabled = true;
      this_.button_.classList.add('disabledGray');
    }
    this_.button_.setAttribute('data-tooltip-id', 'simple-tooltip');
    this_.button_.setAttribute(
      'data-tooltip-content',
      isStartOverAllowed ? 'Reprendre du début' : 'Fonctionnalité désactivée'
    );

    const img = document.createElement('img');
    img.src = '/img/player/go-to-start.svg';
    this_.button_.appendChild(img);

    parent.appendChild(this_.button_);

    this_.eventManager.listen(this_.button_, 'click', () => {
      const currentEpgProgramEntry: APIEpgEntry | null = webStore.getState().webPlayer.currentEpgProgramEntry;
      this_.video.currentTime = absoluteTimeToBrowserTime(
        currentEpgProgramEntry?.live.start ?? Date.now() / 1000 - STARTOVER_MAX_DURATION_SEC,
        browserContext.selectedStreamingType,
        playbackInfo.broadcastType
      );
      document.getElementById('oqee-goToLive-button')?.classList.remove('disabled');
    });
  }
} as any;
const GoToLiveButton = class extends shaka.ui.Element {
  constructor(parent: HTMLDivElement, controls: shaka.ui.Controls, playbackInfo: ChannelPlaybackInfo) {
    super(parent, controls);
    const isStartOverAllowed = !!playbackInfo?.liveChannel?.isStartOverAllowed;
    const this_ = this as any;
    this_.button_ = document.createElement('button');
    this_.button_.id = 'oqee-goToLive-button';
    this_.button_.classList.add('disabledGray');
    this_.button_.disabled = true;
    this_.button_.setAttribute('data-tooltip-id', 'simple-tooltip');
    this_.button_.setAttribute(
      'data-tooltip-content',
      isStartOverAllowed ? 'Revenir au direct' : 'Fonctionnalité désactivée'
    );

    const img = document.createElement('img');
    img.src = '/img/player/go-to-live.svg';
    this_.button_.appendChild(img);

    parent.appendChild(this_.button_);

    this_.eventManager.listen(this_.button_, 'click', () => {
      this_.player.goToLive();
      this_.button_.classList.add('disabled');
    });
  }
} as any;

const CommonFactory = class {
  timelineContainer_: HTMLDivElement;
  playbackInfo_: ChannelPlaybackInfo;
  browserContext_: BrowserContextType;

  constructor(timelineContainer, playbackInfo: ChannelPlaybackInfo, browserContext: BrowserContextType) {
    this.timelineContainer_ = timelineContainer;
    this.playbackInfo_ = playbackInfo;
    this.browserContext_ = browserContext;
  }
};

GoToStartButton.Factory = class extends CommonFactory {
  create(rootElement, controls) {
    return new GoToStartButton(this.timelineContainer_, controls, this.playbackInfo_, this.browserContext_);
  }
};
GoToLiveButton.Factory = class extends CommonFactory {
  create(rootElement, controls) {
    return new GoToLiveButton(this.timelineContainer_, controls, this.playbackInfo_);
  }
};

interface ShakaPlayerUiLiveStartoverButtonsProps {
  videoEl: HTMLVideoElement;
  startOver?: boolean;
  timelineBar: HTMLElement;
  playbackInfo: ChannelPlaybackInfo;
  player: shaka.Player;
}

function ShakaPlayerUiLiveStartoverButtons({
  timelineBar,
  playbackInfo,
  videoEl,
  startOver = false
}: ShakaPlayerUiLiveStartoverButtonsProps) {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const currentEpgProgramEntry: APIEpgEntry | null = useWebStoreState(state => state.webPlayer.currentEpgProgramEntry);

  React.useEffect(() => {
    // Register our factory with the controls, so controls can create button instances.
    shaka.ui.Controls.registerElement(
      /* This name will serve as a reference to the button in the UI configuration object */
      'oqee_goToStart_button',
      new GoToStartButton.Factory(timelineBar, playbackInfo, browserContext)
    );
    shaka.ui.Controls.registerElement(
      /* This name will serve as a reference to the button in the UI configuration object */
      'oqee_goToLive_button',
      new GoToLiveButton.Factory(timelineBar, playbackInfo, browserContext)
    );
  }, [timelineBar, playbackInfo]);

  React.useEffect(() => {
    const handleStartOver = setTimeout(() => {
      if (startOver) {
        videoEl.currentTime = absoluteTimeToBrowserTime(
          currentEpgProgramEntry?.live.start ?? Date.now() / 1000 - STARTOVER_MAX_DURATION_SEC,
          browserContext.selectedStreamingType,
          playbackInfo.broadcastType
        );
      }
    }, 800);
    return () => clearTimeout(handleStartOver);
  }, [currentEpgProgramEntry?.live.start]);

  return null;
}

export default ShakaPlayerUiLiveStartoverButtons;
