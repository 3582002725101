import React from 'react';
import { useVodHome as useVodHomeBase } from '@oqee/core';

const HANDLED_SECTION_TYPES = ['contents', 'contents_landscape', 'portals'];
const HANDLED_SECTION_LAYOUTS = ['landscape', 'no_layout', 'portrait'];
const HANDLED_ENTRY_TYPES = ['portal', 'vod'];

function filter({ sections, isReady }) {
  return {
    isReady,
    sections: sections
      ? sections
          .filter(
            section => HANDLED_SECTION_TYPES.includes(section.type) && HANDLED_SECTION_LAYOUTS.includes(section.layout)
          )
          .map(section => ({
            ...section,
            entries: section.entries.filter(entry => HANDLED_ENTRY_TYPES.includes(entry.type))
          }))
          .filter(section => section.entries.length)
      : null
  };
}

function useVodHome() {
  const rawVodHome = useVodHomeBase();

  const vodHome = React.useMemo(() => filter(rawVodHome), [rawVodHome]);
  return vodHome;
}

export default useVodHome;
