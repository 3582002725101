import React from 'react';
import clsx from 'clsx';
import { useVodOffersContext } from '@oqee/core';

import Typography from '../shared/Typography';
import Button from '../shared/Button';
import ModalOpaqueBackground from '../shared/ModalOpaqueBackground';

import VodOffersType from './VodOffersType';
import SvodOffers from './Svod/SvodOffers';
import TvodOffers from './Tvod/TvodOffers';
import TvodOffersPrice from './Tvod/TvodOffersPrice';

import './VodOffers.css';

function VodOffers() {
  const { step, onStep, program, selectedOffer, onNext, onCancel } = useVodOffersContext();

  const stepper = React.useCallback(() => {
    switch (step) {
      case 0:
        return <VodOffersType />;
      case 1:
        if (selectedOffer.type === 'SVOD') {
          return <SvodOffers />;
        }
        // EST - TVOD
        return <TvodOffers />;
      case 2:
        // EST - TVOD
        return <TvodOffersPrice />;
      default:
        null;
        break;
    }
  }, [step, selectedOffer.type]);

  const handleCancel = (): void => {
    onCancel();
    onStep(0);
  };

  return (
    <>
      <div className="VodOffers">
        <div className="VodOffersHeader">
          <Typography variant="h4">{program.content.title}</Typography>
        </div>
        {stepper()}
        <div className={step === 0 ? 'VodOffersFooter' : clsx('VodOffersFooter', 'VodOffersFooterSteps')}>
          {step > 0 && (
            <Typography variant="body1" className="VodOffersFooterStep">
              Étape {step}/{selectedOffer.type === 'SVOD' ? 2 : 3}
            </Typography>
          )}
          <div className="VodOffersFooterButton">
            <Button label="Annuler" onClick={handleCancel} />
            <Button label="Continuer" type="primary" onClick={onNext} />
          </div>
        </div>
      </div>
      <ModalOpaqueBackground />
    </>
  );
}

export default VodOffers;
