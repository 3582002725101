import React from 'react';
import clsx from 'clsx';
import { Typography } from '@oqee/ui';
import { argb2rgba, formatPrice, useVodOffersContext } from '@oqee/core';
import { APIVodOffer } from '@oqee/core/src/api/types/APIVodOffer';

import Breadcrumb from '../../shared/Breadcrumb';
import ButtonBack from '../../shared/ButtonBack';
import VodProviderInfo from '../VodOffersProviderInfo';

import './TvodOffersPrice.css';

function OfferStepList() {
  const { onBack, selectedOffer, offerListType, onSelectOffer } = useVodOffersContext();

  const sortOfferPrice: APIVodOffer[] = React.useMemo(
    () =>
      (selectedOffer.type &&
        offerListType[selectedOffer.type].sort((a: APIVodOffer, b: APIVodOffer) => a.price - b.price)) ||
      [],
    [offerListType, selectedOffer.type]
  );

  const vodOffersList: APIVodOffer[] = React.useMemo(
    () =>
      Object.values(sortOfferPrice).filter(
        (offer: APIVodOffer) => offer.provider_id === selectedOffer?.offerProvider?.id
      ) || [],
    [sortOfferPrice]
  );

  const displayOriginalPrice = (provider: APIVodOffer) => {
    const { is_promo, price, original_price } = provider;
    return (
      <div className="VodOffersPriceContainer">
        {is_promo && (
          <div className="VodOffersOriginalPrice">
            <Typography variant="body3" className="VodOffersOriginalPriceText">
              {formatPrice(original_price, '€')}
              <hr className="VodOffersOriginalPriceLine" />
            </Typography>
          </div>
        )}
        <Typography variant="body3" style={{ color: argb2rgba('#FFBDBDBD') }}>
          {formatPrice(price, '€')}
        </Typography>
      </div>
    );
  };

  const displayProviders = (offerPurchase: APIVodOffer, key: number) => {
    const { id, name } = offerPurchase;
    return (
      <div
        onClick={() => onSelectOffer({ key, offerPurchase })}
        key={id}
        className={clsx('VodSOffersPriceListElement', {
          checked: selectedOffer.key === key
        })}
      >
        <div className="VodOffersPriceListElementContainer">
          <div className="VodOffersPriceListElementText">
            <Typography variant="body2">{name}</Typography>
            {displayOriginalPrice(offerPurchase)}
          </div>
          <div>
            <img alt="" src={`/img/checkbox_${selectedOffer.key === key ? 'checked' : 'unchecked'}.svg`} />
          </div>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    if ((!selectedOffer.offerPurchase || selectedOffer.key === 0) && vodOffersList.length > 0) {
      onSelectOffer({ key: 0, offerPurchase: vodOffersList[0] });
    } else {
      onSelectOffer({ key: selectedOffer.key, offerPurchase: selectedOffer.offerPurchase });
    }
  }, [selectedOffer.offerPurchase, selectedOffer.key]);

  return (
    <div className="VodOffersPriceContent">
      <div className="VodOffersPriceContentLeft">
        <Breadcrumb>{<ButtonBack onClick={onBack} />}</Breadcrumb>
        <Typography variant="body3" bold>
          Sélectionnez une offre :
        </Typography>
        <div className="VodOffersPriceList">
          {vodOffersList.map((offer: APIVodOffer, key: number) => {
            return displayProviders(offer, key);
          })}
        </div>
      </div>
      <VodProviderInfo />
    </div>
  );
}

export default OfferStepList;
