import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Typography from '../../../../../shared/Typography';

import './ShakaPlayerUiLangModal.css';

const AUDIO_LABELS = [
  { label: 'Français', codes: ['vf', 'fre', 'fra', 'fr'] },
  { label: 'Version originale', codes: ['vo', 'qaa', 'original audio'] },
  { label: 'Multilingue', codes: ['vm'] },
  { label: 'Audio description', codes: ['qad'] },
  { label: 'Anglais', codes: ['eng', 'en'] },
  { label: 'Italien', codes: ['ita', 'it'] },
  { label: 'Espagnol', codes: ['spa', 'es'] }
];

const NO_TEXT_TRACK = { id: 'NONE', language: 'NONE', roles: [] };

const SUBTITLES_LABELS = [
  { label: 'Aucun', codes: [NO_TEXT_TRACK.id] },
  { label: 'Français', codes: ['vf', 'fre', 'fra', 'fr'] },
  { label: 'Version originale', codes: ['vo', 'qaa', 'original audio'] },
  { label: 'Multilingue', codes: ['vm'] },
  { label: 'Français malentendant', codes: ['qad'] },
  { label: 'Anglais', codes: ['eng', 'en'] },
  { label: 'Italien', codes: ['ita', 'it'] },
  { label: 'Espagnol', codes: ['spa', 'es'] }
];

interface ShakaPlayerUiLangModalProps {
  player: shaka.Player;
}

type Track = shaka.extern.Track;
type LanguageRole = shaka.extern.LanguageRole;

function ShakaPlayerUiLangModal({ player }: ShakaPlayerUiLangModalProps) {
  const activeVariantTrack: Track | undefined = player.getVariantTracks().find(track => track.active);
  const activeTextTrack: Track | undefined = player.getTextTracks().find(track => track.active);

  // the 2 following states contain audio and text values selected by user
  // that's why they are undefined on modal first render, and stay undefined if user does not change values
  // this allows to call selectAudioLanguage() and setTextTrackVisibility()/selectTextTrack() in useEffect() only when required
  const [userDefinedLanguageRole, setUserDefinedLanguageRole] = React.useState<LanguageRole | undefined>();
  const [userDefinedTextTrack, setUserDefinedTextTrack] = React.useState<Track | typeof NO_TEXT_TRACK | undefined>();

  React.useEffect(() => {
    if (userDefinedLanguageRole) {
      player.selectAudioLanguage(userDefinedLanguageRole.language, userDefinedLanguageRole.role);
    }
  }, [userDefinedLanguageRole]);

  React.useEffect(() => {
    if (userDefinedTextTrack === NO_TEXT_TRACK) {
      player.setTextTrackVisibility(false);
    } else if (userDefinedTextTrack) {
      player.selectTextTrack(userDefinedTextTrack as Track);
      player.setTextTrackVisibility(true);
    }
  }, [userDefinedTextTrack]);

  return (
    <ReactTooltip
      id="player-lang-tooltip"
      isOpen={true}
      style={{ borderRadius: 8, zIndex: 1000 }}
      opacity={1}
      clickable
      noArrow
    >
      {player.getAudioLanguagesAndRoles().length > 0 && (
        <div id="ShakaPlayerUiLangModalContent">
          <div className="audio">
            <table>
              <thead>
                <tr>
                  <Typography variant="body1" bold className="Title">
                    Audio
                  </Typography>
                </tr>
              </thead>
              <tbody>
                {player.getAudioLanguagesAndRoles().map((languageRole: LanguageRole, i: number) => {
                  const { language, role } = languageRole;
                  const label: string = AUDIO_LABELS.find(({ codes }) => codes.includes(language))?.label ?? 'Inconnu';
                  const activeLanguageRole: LanguageRole | undefined = userDefinedLanguageRole
                    ? userDefinedLanguageRole
                    : activeVariantTrack && {
                        language: activeVariantTrack.language,
                        role: activeVariantTrack.roles?.[0],
                        label: activeVariantTrack.label
                      };
                  const isActiveTrack: boolean =
                    activeLanguageRole?.language === language && (role === '' || activeLanguageRole?.role === role);

                  const handleClick = () => setUserDefinedLanguageRole(languageRole);

                  return (
                    <tr key={i}>
                      <td onClick={handleClick}>
                        <Typography variant="body2" className="Item">
                          {label}
                          {role === 'description' && ' (Audio description)'}
                        </Typography>
                      </td>
                      <td width={18} height={30}>
                        {isActiveTrack && <img src="/img/player/checkmark.svg" />}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {player.getTextTracks().length > 0 && (
            <div className="subtitles">
              <table>
                <thead>
                  <th>
                    <Typography variant="body1" bold className="Title">
                      Sous-titres
                    </Typography>
                  </th>
                </thead>
                <tbody>
                  {[NO_TEXT_TRACK, ...player.getTextTracks()].map((track: Track | typeof NO_TEXT_TRACK, i: number) => {
                    const { language, roles, id } = track;
                    const role: string = roles?.[0];
                    const label: string =
                      SUBTITLES_LABELS.find(({ codes }) => codes.includes(language))?.label ?? 'Inconnu';
                    const isActiveTrack: boolean = userDefinedTextTrack
                      ? userDefinedTextTrack.id === id
                      : activeTextTrack
                      ? activeTextTrack.id === id
                      : track === NO_TEXT_TRACK;

                    const handleClick = () => setUserDefinedTextTrack(track);

                    return (
                      <tr key={i}>
                        <td onClick={handleClick}>
                          <Typography variant="body2" className="Item">
                            {label}
                            {role === 'caption' && ' (Malentendant)'}
                          </Typography>
                        </td>
                        <td width={18} height={30}>
                          {isActiveTrack && <img src="/img/player/checkmark.svg" />}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </ReactTooltip>
  );
}

export default ShakaPlayerUiLangModal;
