import React from 'react';

import MainLayout from '../../components/layout/MainLayout';
import { HeaderNav } from '../../utils/webUtils';
import ContentRows from '../../components/content/ContentRows';
import useVodHome from '../../hooks/useVodHome';
import Spinner from '../../components/shared/Spinner/Spinner';
import ParentalGuard from '../../components/shared/ParentalGuard';

function VodHomePortalPage() {
  const { isReady, sections } = useVodHome();

  return (
    <ParentalGuard isAdult={false}>
      <MainLayout activeHeaderNav={HeaderNav.VOD} fbScreenName="vod_catalog">
        {isReady ? <ContentRows rows={sections || []} showTileTitle={false} /> : <Spinner testId="vod-pending" />}
      </MainLayout>
    </ParentalGuard>
  );
}

export default VodHomePortalPage;
