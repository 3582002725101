import shaka from 'shaka-player/dist/shaka-player.ui';
import React from 'react';
import ShakaPlayerUiLiveRecordModal from './ShakaPlayerUiLiveRecordModal';
import { useWebStoreState } from '../../../../../store/webStoreUtils';
import { ErrorModelState } from '@oqee/core';

const RecordButton = class extends shaka.ui.Element {
  constructor(parent, controls, setOpen) {
    super(parent, controls);
    const this_ = this as any;

    this_.button_ = document.createElement('img');
    this_.button_.src = '/img/player/record.svg';
    this_.button_.id = 'record-button';
    this_.button_.setAttribute('data-tooltip-id', 'player-record-tooltip');

    this_.button_.addEventListener('click', () => setOpen(o => !o));

    parent.appendChild(this_.button_);
  }
} as any;

// Factory that will create a button at run time.
RecordButton.Factory = class {
  bottomBar_;
  setOpen_;

  constructor(bottomBar, setOpen) {
    this.bottomBar_ = bottomBar;
    this.setOpen_ = setOpen;
  }
  create(rootElement, controls) {
    return new RecordButton(this.bottomBar_, controls, this.setOpen_);
  }
};

function ShakaPlayerUiLiveRecordButton({ bottomBar }) {
  const errorState: ErrorModelState = useWebStoreState(state => state.error);
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    shaka.ui.Controls.registerElement(
      /* This name will serve as a reference to the button in the UI configuration object */
      'oqee_record_button',
      new RecordButton.Factory(bottomBar, setOpen)
    );

    const handleClickOutsideRecordModal = (e: MouseEvent) => {
      const langModal: HTMLElement | null = document.getElementById('ShakaPlayerUiLiveRecordModalContent');
      if (langModal && !langModal.contains(e.target as Node)) {
        setOpen(false);
        e.stopPropagation();
      }
    };

    window.addEventListener('click', handleClickOutsideRecordModal, true);

    return () => window.removeEventListener('click', handleClickOutsideRecordModal);
  }, []);

  React.useEffect(() => {
    if (errorState.code) {
      // close record modal when an "error" (duplicate_record, npvr_denied etc) occurs
      // while creating a record, in order to only display the error message modal
      setOpen(false);
    }
  }, [errorState]);

  if (isOpen) return <ShakaPlayerUiLiveRecordModal closeModal={() => setOpen(false)} />;
  else return null;
}

export default ShakaPlayerUiLiveRecordButton;
