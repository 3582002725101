import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Feature, useAccountProfile } from '@oqee/core';

import { Button, SearchEvideeIcon } from '@oqee/ui';

import { HeaderNav } from '../../../utils/webUtils';
import { useWebStoreState } from '../../../store/webStoreUtils';
import Typography from '../../shared/Typography';
import Avatar from '../../shared/Avatar';

import './Header.css';

interface HeaderProps {
  activeHeaderNav?: HeaderNav;
}

function Header({ activeHeaderNav }: HeaderProps) {
  const features: Feature[] = useWebStoreState(state => state.auth.features);
  const { getAvatarUrl, activeProfile } = useAccountProfile();

  return (
    <div className="HeaderDiv">
      <div className="HeaderDivInner">
        <Link to="/home">
          <img className="HeaderLogo" alt="" src="/img/oqee-by-free1.svg" />
        </Link>
        <div className="HeaderMenu">
          <Link
            to="/home"
            aria-label="pour vous"
            data-test-id="header-link-home"
            className={clsx('HeaderMenuItem', { active: activeHeaderNav === HeaderNav.FOR_YOU })}
          >
            <Typography variant="body1" bold>
              Pour vous
            </Typography>
          </Link>
          <Link
            to="/home/channels"
            aria-label="chaînes"
            data-test-id="header-link-channels"
            className={clsx('HeaderMenuItem', { active: activeHeaderNav === HeaderNav.CHANNELS })}
          >
            <Typography variant="body1" bold>
              Chaînes
            </Typography>
          </Link>
          <Link
            to="/home/replay"
            aria-label="replay"
            data-test-id="header-link-replay"
            className={clsx('HeaderMenuItem', { active: activeHeaderNav === HeaderNav.REPLAY })}
          >
            <Typography variant="body1" bold>
              Replay
            </Typography>
          </Link>
          <Link
            to="/home/oqee_cine"
            aria-label="OQEE Ciné"
            data-test-id="header-link-oqee-cine"
            className={clsx('HeaderMenuItem', { active: activeHeaderNav === HeaderNav.OQEE_CINE })}
          >
            <Typography variant="body1" bold>
              OQEE Ciné
            </Typography>
          </Link>
          {(features.includes(Feature.SUBSCRIPTION) || !features.includes(Feature.OQEE_CINE_ONLY)) && (
            <Link
              to="/home/vod"
              aria-label="VOD"
              data-test-id="header-link-vod"
              className={clsx('HeaderMenuItem', { active: activeHeaderNav === HeaderNav.VOD })}
            >
              <Typography variant="body1" bold>
                VOD
              </Typography>
            </Link>
          )}
          {features.includes(Feature.MY_LIST) && (
            <Link
              to="/home/my_list"
              aria-label="ma liste"
              data-test-id="header-link-my-list"
              className={clsx('HeaderMenuItem', { active: activeHeaderNav === HeaderNav.MY_LIST })}
            >
              <Typography variant="body1" bold>
                Ma liste
              </Typography>
            </Link>
          )}
        </div>
        <div className="HeaderSecondaryMenu">
          <Link aria-label="recherche" data-test-id="header-link-search" to="/home/search">
            <Button
              icon={SearchEvideeIcon}
              selected={activeHeaderNav === HeaderNav.SEARCH}
              rounded
              className={clsx({ focused: activeHeaderNav === HeaderNav.SEARCH })}
            />
          </Link>
          {activeProfile && (
            <Link to="/settings">
              <div className="HeaderProfileIcon" data-testid="app-header-avatar" data-testdata={activeProfile.username}>
                <Avatar
                  avatarUrl={getAvatarUrl(activeProfile)}
                  active={activeHeaderNav === HeaderNav.SETTINGS}
                  color={activeProfile.avatar_color}
                  size="small"
                />
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
