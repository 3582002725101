import React from 'react';
import { useParams } from 'react-router-dom';
import { APIVodProvider, useVodProgramDetails } from '@oqee/core';

import PlayerPageLayout from '../../components/layout/PlayerPageLayout';
import SVodPlayer from '../../components/player/SVodPlayer';
import { useWebStoreState } from '../../store/webStoreUtils';
import ParentalGuard from '../../components/shared/ParentalGuard';

type SvodPlayerPageParams = {
  portalId: string;
  contentId: string;
};

function SVodPlayerPage() {
  const { portalId, contentId } = useParams() as SvodPlayerPageParams;
  const { isReady, getOffers, programEntry } = useVodProgramDetails(Number(contentId));
  const providers: { [key: string]: APIVodProvider } = useWebStoreState(
    state => state.channel.servicePlan.vod_providers
  );

  if (!isReady) return null;

  const svodProvider: APIVodProvider | undefined = Object.values(providers).find(provider => {
    return provider.portal_id === Number(portalId);
  });

  const offers: any[] = svodProvider && getOffers('svod', svodProvider.id);
  const offerId: number | undefined = offers?.[0]?.id;

  if (offerId === undefined) return null;

  return (
    <PlayerPageLayout>
      <ParentalGuard isAdult={programEntry.vod?.parental_rating >= 18} requireNonExpiredToken={true}>
        <SVodPlayer offerId={offerId} />
      </ParentalGuard>
    </PlayerPageLayout>
  );
}

export default SVodPlayerPage;
