import React from 'react';
import { MixedLine, Typography } from '@oqee/ui';
import { useStoreActions } from 'easy-peasy';
import {
  MINUTE,
  useNewRecordIsLive,
  useNewRecordMarginAfter,
  useNewRecordMarginBefore,
  useNpvrQuota,
  useNpvrQuotaUsage,
  useProgramRecording
} from '@oqee/core';
import { useModal } from '../../../shared/Modal';
import Step from '../../../shared/Step';

import './RecordModal.css';

type RecordModalProps = {
  program: any;
  onClose: (isCreated: boolean) => void;
};

function RecordMarginBefore({ step }) {
  const [marginBefore, setMarginBefore] = useNewRecordMarginBefore();

  return (
    <div className="RecordModalSettingItem">
      <Typography variant="body2">Marge avant le programme</Typography>
      <Step
        label="minute"
        value={marginBefore / MINUTE}
        onChange={change => {
          setMarginBefore(marginBefore + step * change);
        }}
      />
    </div>
  );
}

function RecordMarginAfter({ step }) {
  const [marginAfter, setMarginAfter] = useNewRecordMarginAfter();

  return (
    <div className="RecordModalSettingItem">
      <Typography variant="body2">Marge après le programme</Typography>
      <Step
        label="minute"
        value={marginAfter / MINUTE}
        onChange={change => {
          setMarginAfter(marginAfter + step * change);
        }}
      />
    </div>
  );
}

function RecordFormHint() {
  const quota = useNpvrQuota();
  const usage = useNpvrQuotaUsage(quota, undefined, 'web');

  return (
    <Typography variant="body3" color="grey-3">
      <br />
      {usage?.footer}
    </Typography>
  );
}

function RecordModalContent({ program }) {
  const { title, entity_corner, entity_dataline, subtitle } = program.content;
  const isLive = useNewRecordIsLive();

  const mixedLine = React.useMemo(() => {
    return {
      type: 'mixedLine',
      items: [...(entity_corner?.items || []), { type: 'separator' }, ...(entity_dataline?.items || [])]
    };
  }, [entity_dataline, entity_corner]);

  return (
    <div className="RecordModal">
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body2">{subtitle}</Typography>
      {mixedLine && <MixedLine program={program} mixedLine={mixedLine} badgeSize="small" />}
      <div className="RecordModalSettings">
        {!isLive && <RecordMarginBefore step={5 * MINUTE} />}
        <RecordMarginAfter step={5 * MINUTE} />
      </div>
      <div className="RecordModalFooter">
        <Typography variant="body3" color="grey-3">
          La durée maximale d’un enregistrement unique est de 4 heures.
        </Typography>
        <RecordFormHint />
      </div>
    </div>
  );
}

function RecordModal({ program, onClose }: RecordModalProps) {
  const { title } = program.content;
  const isLive = program.isLive();
  const triggerError = useStoreActions<any>(actions => actions.error.trigger);

  const { Modal, closeModal, isClosedModal } = useModal(
    {
      hasPadding: false,
      description: <RecordModalContent program={program} />,
      title: 'Enregistrer ce programme',
      validateButtonLabel: 'Enregistrer',
      onValidate: () => {
        createRecord();
        //TBD: find solution to handle modal on error
      },
      onCancel: () => {
        closeModal();
      }
    },
    false,
    true
  );

  const { createRecord, isCreated } = useProgramRecording({
    source: 'multi',
    onCreated: () => {
      closeModal();
    },
    program
  });

  React.useEffect(() => {
    if (isCreated && isLive) {
      triggerError({ code: 'record_program_already_started', data: { title } });
    }
  }, [isCreated, isLive, title, triggerError]);

  React.useEffect(() => {
    if (isClosedModal) {
      onClose(!!isCreated);
    }
  }, [isClosedModal, isCreated]);

  return <Modal />;
}

export default RecordModal;
