enum HeaderNav {
  FOR_YOU,
  CHANNELS,
  REPLAY,
  SETTINGS,
  OQEE_CINE,
  VOD,
  MY_LIST,
  SEARCH
}

enum MyListNav {
  ALL,
  RECORDS,
  VOD
}

enum ChannelsNav {
  ALL,
  FAVORITES
}

export { HeaderNav, MyListNav, ChannelsNav };
