import React from 'react';
import Logger from 'js-logger';
import shaka from 'shaka-player/dist/shaka-player.ui';
import toast from 'react-hot-toast';
import { ActionCreator } from 'easy-peasy';
import { api, StreamingType, CONSTANTS, oqeeReport } from '@oqee/core';

import { BrowserContext, BrowserContextType } from '../../context/BrowserContextProvider';
import useWebError from '../../../hooks/useWebError';
import errorRegistry, { WebErrorRegistryEntry } from '../../../lib/errorRegistry';
import { ShakaPlayerProps } from './ShakaPlayer';
import Typography from '../../shared/Typography';
import { useWebStoreActions, useWebStoreState } from '../../../store/webStoreUtils';
import { STARTOVER_MAX_DURATION_SEC } from '../../../utils/playerUtils';

const logger = Logger.get('ShakaPlayerController');

interface ShakaPlayerEventError extends Event {
  detail: shaka.util.Error;
}

interface ShakaPlayerControllerProps extends ShakaPlayerProps {
  player: shaka.Player;
}

const { DRM_SYSTEM } = api.constant;

function shakaCodeToRegistryCode(shakaCode: string): string {
  return `shaka_error_${shakaCode}`;
}

/** makes JSON.stringify able to print details about a js Error object */
function stringifyErrorReplacer(_key, value) {
  if (value?.constructor?.name?.endsWith('Error')) {
    return {
      name: value.name,
      message: value.message,
      cause: value.cause
    };
  }
  return value;
}

function ShakaPlayerController({ player, videoEl, playbackInfo, streamRef }: ShakaPlayerControllerProps) {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const volume: number = useWebStoreState(state => state.webPlayer.volume);
  const setVolume: ActionCreator<number> = useWebStoreActions(state => state.webPlayer.setVolume);
  const { trigger: triggerError } = useWebError();

  const [toastId, setToastId] = React.useState<string | null>(null);

  const eventManager = new shaka.util.EventManager();
  const { promoToken, drmSystem, drmServer, broadcastType, manifestUrl } = playbackInfo;
  const isLive = broadcastType === 'live';
  const isReplay = broadcastType === 'replay';
  const isVod = broadcastType === 'vod';
  const isRecord = broadcastType === 'record';
  const isTrailer = broadcastType === 'trailer';

  /**
   * Configure player & setup error handler
   */
  React.useEffect(() => {
    const config = {
      streaming: {
        bufferingGoal: 30,
        rebufferingGoal: 1,
        stallSkip: 10
      },
      abr: {
        defaultBandwidthEstimate: 7000000
      },
      manifest: {
        availabilityWindowOverride: STARTOVER_MAX_DURATION_SEC,
        dash: {
          ignoreMinBufferTime: true,
          manifestPreprocessorTXml: xml => {
            if (isRecord) xml.attributes['type'] = 'static';
            return xml;
          }
        }
      },
      ...(drmSystem && {
        drm: {
          servers: {
            [drmSystem]: drmServer
          },
          advanced: {
            ...(browserContext.selectedStreamingType === StreamingType.DASH && {
              [DRM_SYSTEM.widevine]: {
                videoRobustness: 'SW_SECURE_CRYPTO',
                audioRobustness: 'SW_SECURE_CRYPTO'
              }
            }),
            ...(browserContext.fairplayCertificate && {
              [DRM_SYSTEM.fairplay]: {
                serverCertificate: browserContext.fairplayCertificate
              }
            })
          }
        },
        playRangeStart: isRecord ? playbackInfo.timeRange?.start : undefined,
        playRangeEnd: (isRecord && playbackInfo.timeRange?.end) || undefined
      })
    };
    player.configure(config);

    function handleError(event) {
      const { detail } = event as unknown as ShakaPlayerEventError;
      const { code, data, severity } = detail;
      logger.error(`An error occured in player`, event);

      if (severity !== shaka.util.Error.Severity.CRITICAL) return;

      const errorRegistryCode: string = shakaCodeToRegistryCode(code);
      const errorRegistryEntry: WebErrorRegistryEntry | undefined = errorRegistry[errorRegistryCode];

      if (!errorRegistryEntry || !errorRegistryEntry.isRecoverable) {
        triggerError({
          code: errorRegistryCode,
          title: `Une erreur de lecture est survenue`,
          description: () => (
            <Typography variant="body3">{`data=${JSON.stringify(data, stringifyErrorReplacer, 1)}`}</Typography>
          )
        });

        oqeeReport.log(streamRef.current, { code: errorRegistryCode, originalError: detail });
      }
    }

    player.addEventListener('error', handleError);
    return () => {
      player.removeEventListener('error', handleError);
    };
  }, [player, drmSystem, drmServer, promoToken]);

  /**
   * Load manifest & try playing
   */
  React.useEffect(() => {
    logger.debug('player.load', manifestUrl);

    function tryPlaying() {
      // in case of autoplay error, try playing muted
      videoEl.play().catch(error => {
        if (videoEl.muted) {
          logger.error('muted videoEl.play() failed', error);
          videoEl.muted = false;
          return;
        }
        logger.warn('initial videoEl.play() failed', error);

        if (error.name === 'NotAllowedError') {
          // unmuted play failed : try muted
          logger.warn('try playing muted');
          videoEl.muted = true;
          return tryPlaying();
        }
      });
    }

    streamRef.current = {
      ...streamRef.current,
      broadcastType,
      playbackInfo,
      drmMessages: [],
      streamingType: browserContext.selectedStreamingType
    };

    player
      .load(manifestUrl)
      .then(tryPlaying)
      .catch((e: shaka.util.Error) => {
        const errorRegistryCode: string = shakaCodeToRegistryCode(e.code);
        if (errorRegistry[errorRegistryCode]) triggerError({ code: errorRegistryCode });
        logger.error(`An error occured while calling player.load('${manifestUrl}')`, e);
      });

    return () => {
      // store current volume in redux on cleanup
      setVolume(videoEl.volume);
    };
  }, [player, manifestUrl]);

  /**
   * Save playing position on back
   */
  React.useEffect(() => {
    return () => {
      if (isReplay && playbackInfo.programId) {
        const programId: number = playbackInfo.programId;
        const position: number = Math.round((videoEl.currentTime / videoEl.duration) * CONSTANTS.PLAYBACK_POSITION_MAX);

        api.replay.savePlaybackPosition(programId, position).then(() => {
          logger.debug(`Saved playing position replay #${programId}: ${position}`);
        });
      }
      if (isVod && playbackInfo.programId) {
        const programId: number = playbackInfo.programId;
        const position: number = Math.round((videoEl.currentTime / videoEl.duration) * CONSTANTS.PLAYBACK_POSITION_MAX);

        api.vod.savePlaybackPosition(programId, position).then(() => {
          logger.debug(`Saved playing position vod #${programId}: ${position}`);
        });
      }
      if (isRecord) {
        const start: number = playbackInfo.timeRange.start;
        const recordId: string = playbackInfo.recordId;
        const durationInSeconds: number = videoEl.duration - start;
        const currentTimeInSeconds: number = videoEl.currentTime - start;
        const position: number = Math.round(
          (currentTimeInSeconds / durationInSeconds) * CONSTANTS.PLAYBACK_POSITION_MAX
        );

        api.npvr.savePlaybackPosition(recordId, position).then(() => {
          logger.debug(`Saved playing position record #${recordId}: ${position}`);
        });
      }
    };
  }, [isReplay, isVod, isRecord, videoEl]);

  /**
   * Handle "loaded" event
   */
  React.useEffect(() => {
    function setVideoPosition() {
      if (isReplay || isVod) {
        const position: number = Math.round(playbackInfo?.position ?? 0);
        if (position > 0) {
          const positionInSeconds: number = Math.round((position * videoEl.duration) / CONSTANTS.PLAYBACK_POSITION_MAX);
          const mediaDuration: number = Math.round(videoEl.duration);
          videoEl.currentTime = positionInSeconds >= mediaDuration ? 0 : positionInSeconds;
        }
      }

      if (isRecord) {
        const position: number = playbackInfo?.position ?? 0;
        if (position > 0) {
          const start: number = playbackInfo?.timeRange?.start;
          const durationInSeconds: number = videoEl.duration - start;
          const positionInSeconds: number = Math.round(
            (position * durationInSeconds) / CONSTANTS.PLAYBACK_POSITION_MAX + start
          );
          videoEl.currentTime = positionInSeconds;
        }
      }
    }

    function handleLoadedEvent() {
      if (isLive) {
        // Show notice message
        const noticeMessage = playbackInfo?.liveChannel?.noticeMessage;
        noticeMessage && setToastId(toast(noticeMessage, { duration: 10000, style: { marginTop: '123px' } }));

        player.goToLive();
      }

      if (isReplay || isRecord || isVod || isTrailer) {
        // Apply external subtitles
        Object.entries(playbackInfo.subtitles).forEach(([lang, url]: [string, string]) =>
          player.addTextTrackAsync(url, lang, 'subtitle')
        );
      }

      // show/hide language button
      const showLangButton = player.getAudioLanguagesAndRoles().length > 0 || player.getTextTracks().length > 0;
      const langButton: any = document.getElementById('lang-button');
      langButton.style.display = showLangButton ? 'block' : 'none';

      // Set video position
      setVideoPosition();

      // Apply volume user setting
      videoEl.volume = volume;
    }

    eventManager.listen(player, 'loaded', handleLoadedEvent);
    return () => {
      toastId && toast.remove(toastId);
      toastId && setToastId(null);
      eventManager.unlisten(player, 'loaded', handleLoadedEvent);
    };
  }, [player, playbackInfo, volume]);

  React.useEffect(() => {
    return () => {
      // hide notice message on cleanup
      toastId && toast.remove(toastId);
    };
  }, [toastId]);

  return null;
}

export default ShakaPlayerController;
