import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { APIReplayProgramDetailResponse, api, CONSTANTS, useVodProgramDetails } from '@oqee/core';

type DeeplinkPlayerPageParams = {
  id: string;
};

function DeeplinkPlayerPage() {
  const { id } = useParams() as DeeplinkPlayerPageParams;
  const { isReady, getOffers } = useVodProgramDetails(Number(id));
  const [rppResponse, setRppResponse] = React.useState<APIReplayProgramDetailResponse>();

  React.useEffect(() => {
    api.replay.fetchMetadata(id).then(setRppResponse);
  }, []);

  if (!isReady) return null;

  const oqeeCineOffers: any[] = getOffers('svod', CONSTANTS.OQEE_CINE_PROVIDER_ID);
  const oqeeCineOfferId: number | undefined = oqeeCineOffers?.[0]?.id;

  // 1. Is it Oqee ciné content?
  if (oqeeCineOfferId !== undefined)
    return <Navigate to={`/svod/portal/${CONSTANTS.OQEE_CINE_PORTAL_ID}/${id}/play`} replace />;
  // 2. Is it replay content?
  if (rppResponse?.success) return <Navigate to={`/replay/${id}/play`} replace />;

  return null;
}

export default DeeplinkPlayerPage;
