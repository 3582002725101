import React from 'react';
import { useAccountProfile } from '@oqee/core';

import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import Typography from '../../../../components/shared/Typography';
import NavHeader from '../../../../components/layout/NavHeader';
import Button from '../../../../components/shared/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Toggle from '../../../../components/shared/Toggle';

import './ProfileDefault.css';

function ProfileDefault() {
  const { setActive, selectProfileOnStart, setSelectProfileOnStart } = useAccountProfile();
  const navigate: NavigateFunction = useNavigate();

  const handleBack = () => navigate('/settings');

  const onToggle = () => {
    setSelectProfileOnStart(!selectProfileOnStart);
  };

  const onConfirm = React.useCallback(() => {
    handleBack();
  }, [setActive]);

  return (
    <UserProfileLayout>
      <div className="ProfileDefault">
        <NavHeader onBack={handleBack} />

        <Typography variant="h1">Affichage des profils au démarrage</Typography>

        <img
          src="/img/settings/profile/default-profile-background.png"
          width={250}
          height={240}
          alt=""
          className="ProfileDefaultBackground"
        />

        <Toggle label="Affichage au démarrage" value={selectProfileOnStart} onToggle={onToggle} />

        <div className="ProfileColorContinueButton">
          <Button label="Confirmer" type="primary" onClick={onConfirm} />
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default ProfileDefault;
