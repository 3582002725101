import React from 'react';
import { useAccountProfile } from '@oqee/core';
import { useParams, NavigateFunction, useNavigate } from 'react-router-dom';

import './ProfileEditionMenu.css';
import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import Typography from '../../../../components/shared/Typography';
import NavHeader from '../../../../components/layout/NavHeader';
import Avatar from '../../../../components/shared/Avatar';
import Button from '../../../../components/shared/Button';

type ProfileEditionMenuProps = {
  profileId: string;
};

function ProfileEditionMenu() {
  const { profileId } = useParams() as ProfileEditionMenuProps;
  const navigate: NavigateFunction = useNavigate();

  const { getAvatarUrl, getProfile } = useAccountProfile();

  const currentProfile = getProfile(profileId);

  const handleBack = () => navigate('..');

  const handleNameEdit = () => navigate('name');

  const handleAvatarEdit = () => navigate('avatar');

  const handleProfileRemove = () => navigate(`../remove/${profileId}`);

  return (
    <UserProfileLayout>
      <div className="ProfileEditionMenu">
        <NavHeader onBack={handleBack} />
        <div className="ProfileEditionMenuTitle">
          <Typography variant="h1">Modifier un profil</Typography>
        </div>

        <div className="ProfileEditionMenuAvatar">
          <Avatar avatarUrl={getAvatarUrl(currentProfile)} active={false} color={currentProfile.avatar_color} />
          <Typography variant="h4">{currentProfile.username}</Typography>
        </div>

        <div className="ProfileEditionMenuButtons">
          <Button label="Modifier le nom" size="large" type="primary" onClick={handleNameEdit} />
          <Button label="Modifier l'avatar et l'âge" size="large" type="secondary" onClick={handleAvatarEdit} />
          <Button label="Supprimer le profil" size="large" type="secondary" onClick={handleProfileRemove} />
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default ProfileEditionMenu;
