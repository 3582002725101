import React, { useState } from 'react';
import { BasketIcon, Button, PlayIcon, ReplayIcon, Typography } from '@oqee/ui';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import ChannelSubscriptionGuard from '../shared/ChannelSubscriptionGuard';
import { Feature, getPromoToken, useProgram, useRecordByContentId } from '@oqee/core';
import { useWebStoreActions, useWebStoreState } from '../../store/webStoreUtils';
import RecordButton from './RecordButton';
import DeleteRecordButton from './DeleteRecordButton';
import { ActionCreator } from 'easy-peasy';

function ChannelCTA({ program, rounded = false }) {
  const features: Feature[] = useWebStoreState(state => state.auth.features);
  const hasNpvrFeature: boolean = features.includes(Feature.NPVR);

  const navigate: NavigateFunction = useNavigate();
  const triggerError: ActionCreator<any> = useWebStoreActions(actions => actions.error.trigger);
  const timestamp: number = useWebStoreState(state => state.channel.timestamp);
  const programUrl = program.getUrl(import.meta.env.VITE_APPLICATION_TYPE);
  const [showSubscription, setShowSubscription] = useState(false);
  const { channel, isLocked, content } = program;
  const isLive = program.isLive();
  const { isStartOverAllowed } = channel;

  const { id } = content;

  const promoToken = getPromoToken(channel, timestamp);

  const recordEntry = useRecordByContentId(id);
  const recordProgram = useProgram(recordEntry);

  const handleClickStartOver = React.useCallback(() => {
    if (!isStartOverAllowed) {
      triggerError({ code: 'not_available_for_this_channel' });
    } else {
      navigate(
        { pathname: `${programUrl}` },
        {
          state: {
            startOver: true
          }
        }
      );
    }
  }, [triggerError, isStartOverAllowed, programUrl, navigate]);

  const getRecordButton = React.useCallback(
    function (color, rounded) {
      if (!hasNpvrFeature) return null;
      return recordProgram ? (
        <DeleteRecordButton program={recordProgram} color={color} rounded={rounded} />
      ) : (
        <RecordButton program={program} color={color} rounded={rounded} />
      );
    },
    [recordProgram, program]
  );

  return (
    <>
      {isLocked && !promoToken ? (
        <ChannelSubscriptionGuard
          channelNumber={channel?.number}
          onCancel={() => setShowSubscription(false)}
          onSuccess={() => setShowSubscription(false)}
          displaySubscription={showSubscription}
        >
          <Button
            onClick={() => setShowSubscription(true)}
            color="primary"
            icon={BasketIcon}
            rounded={rounded}
            iconColor="info"
            tooltip={rounded ? `S'abonner à la chaine` : undefined}
          >
            {!rounded && (
              <Typography variant="body1" bold>
                {`S'abonner à la chaine`}
              </Typography>
            )}
          </Button>
        </ChannelSubscriptionGuard>
      ) : isLive ? (
        <>
          <Link to={`${programUrl}`}>
            <Button
              color="primary"
              icon={PlayIcon}
              rounded={rounded}
              iconColor="info"
              tooltip={rounded ? 'Lecture' : undefined}
            >
              {!rounded && (
                <Typography variant="body1" bold>
                  Lecture
                </Typography>
              )}
            </Button>
          </Link>
          <Button
            color="secondary"
            icon={ReplayIcon}
            rounded={rounded}
            tooltip="Reprendre du début"
            onClick={handleClickStartOver}
          />
          {getRecordButton('secondary', rounded)}
        </>
      ) : (
        getRecordButton('primary', rounded)
      )}
    </>
  );
}

export default ChannelCTA;
