import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useSubscriptionManager, formatPrice } from '@oqee/core';

import { HeaderNav } from '../../../../utils/webUtils';
import MainLayout from '../../../../components/layout/MainLayout';
import NavHeader from '../../../../components/layout/NavHeader';
import { List } from '../../../../components/shared/List';
import Typography from '../../../../components/shared/Typography';
import { ListItemData } from '../../../../components/shared/List/ListItem';
import Spinner from '../../../../components/shared/Spinner';

import './SubscriptionsList.css';

function SubscriptionsList() {
  const navigate: NavigateFunction = useNavigate();
  const subscriptionManager = useSubscriptionManager();
  const { subscriptions } = subscriptionManager;

  const handleBack = () => navigate('/settings');

  if (!subscriptions) {
    return <Spinner />;
  }

  const items: ListItemData[] = subscriptions
    ?.filter(offer => offer.state !== 'unsubscribed')
    .map(({ id, title, price, state, type }) => {
      const subtitle = `${formatPrice(price, '€')}/mois, sans engagement`;
      const textAction =
        state === 'unsubscribed_end_of_month' ? `L'abonnement est en cours de résiliation` : 'Résilier';

      return {
        id,
        title,
        subtitle,
        textAction: price ? textAction : '',
        //to: price && `offer/${type}/${id}`
        to: `offer/${type}/${id}`
      };
    });

  return (
    <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>
      <NavHeader onBack={handleBack} />
      <Typography variant="h2" className="SubscriptionsListTitle">
        Mes abonnements
      </Typography>
      <div className="SubscriptionsList">
        <List items={items} />
      </div>
    </MainLayout>
  );
}

export default SubscriptionsList;
