import React from 'react';

/**
 * @returns a boolean specifying if browser window is currently in fullscreen mode
 */
function useFullscreen() {
  const [isFullscreen, setFullscreen] = React.useState(false);

  const handleFullscreenchange = () => {
    setFullscreen(
      !!(
        document['fullscreenElement'] ||
        document['webkitFullscreenElement'] ||
        document['mozFullScreenElement'] ||
        document['msFullscreenElement']
      )
    );
  };

  React.useEffect(() => {
    window.addEventListener('fullscreenchange', handleFullscreenchange);
    return () => {
      window.removeEventListener('fullscreenchange', handleFullscreenchange);
    };
  }, []);

  return isFullscreen;
}

export default useFullscreen;
