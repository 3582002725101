import ReactDOM from 'react-dom/client';
import React from 'react';
import Logger from 'js-logger';

import { api, oqeeReport, shouldMock } from '@oqee/core';

import { initializeAnalytics } from './lib/analytics';
import App from './App';
import { initializeSentry } from './lib/sentry';

// get platform name from env
const platformName = import.meta.env.VITE_PLATFORM_NAME || 'web';

function initializeOqeeReport() {
  if (!import.meta.env.VITE_ERROR_REPORT_URL) return;

  oqeeReport.init({
    url: import.meta.env.VITE_ERROR_REPORT_URL,
    appVersion: import.meta.env.VITE_VERSION,
    appType: import.meta.env.VITE_APPLICATION_TYPE
  });
}

function initializeApi() {
  api.request.setPlatform(platformName);
  if (shouldMock) return;
  api.url.update({
    baseApiUrl: import.meta.env.VITE_BASE_API_URL
  });
}

function initialize() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  Logger.useDefaults();
  Logger.setLevel(import.meta.env.DEV ? Logger.DEBUG : Logger.INFO);

  const logger = Logger.get('Init');
  logger.info(`RELEASE ${import.meta.env.VITE_RELEASE}`);

  initializeOqeeReport();
  initializeApi();
  initializeAnalytics();
  initializeSentry();
}

function start() {
  initialize();
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
}

if (shouldMock) {
  // Start MSW before rendering application
  import('@oqee/mocks/browser').then(async (browserMocking) => {
    const { worker, commands } = browserMocking;
    await worker.start();
    type AUTWindow = Window & { msw?: any };
    const win = window as AUTWindow;
    if (!win.msw) {
      win.msw = {};
    }
    if (!win.msw.worker) {
      win.msw.worker = worker;
    }

    if (win.msw.initialize) {
      win.msw.initialize({ commands });
    }
    start();
  });
} else {
  start();
}
