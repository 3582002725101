import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import Typography from '../../../../components/shared/Typography';
import NavHeader from '../../../../components/layout/NavHeader';
import ProfileAvatarGrid from '../ProfileAvatarGrid';

import './ProfileManage.css';

function ProfileManage() {
  const navigate: NavigateFunction = useNavigate();

  const handleBack = React.useCallback(() => {
    navigate('..', { relative: 'path' });
  }, [navigate]);

  const handleAvatarClick = (profileId: string) => navigate(`edit/${profileId}`);

  return (
    <UserProfileLayout>
      <div className="ProfileManage">
        <NavHeader onBack={handleBack} />

        <div className="ProfileManageTitle">
          <Typography variant="h1">Modifier un profil</Typography>
        </div>
        <div className="ProfileManageDescription">
          <Typography variant="h4">Sélectionner un profil à modifier</Typography>
        </div>

        <ProfileAvatarGrid onAvatarClick={handleAvatarClick} />
      </div>
    </UserProfileLayout>
  );
}

export default ProfileManage;
