import React from 'react';
import { useVodOffersContext, PICTURE_FORMAT_WEB, useVodProviderLogo } from '@oqee/core';
import { ThumbnailPicture, MixedLine } from '@oqee/ui';
import { APIVodOffer } from '@oqee/core/src/api/types/APIVodOffer';

import Typography from '../shared/Typography';

import './VodOffersProviderInfo.css';

function VodProviderInfo() {
  const { program } = useVodOffersContext();
  const { content } = program;
  const {
    global_data
  } = content;
  const thumbnailUrl = program.getThumbnailUrl(PICTURE_FORMAT_WEB.THUMBNAIL);
  const providerLogos = useVodProviderLogo(content)

  const mixedLineFiltered = React.useCallback((value1: string, value2: string) => {
    return global_data?.items.reduce(
      (acc, item: APIVodOffer) => {
        if (item.type === value1) {
          acc.items.push(item);
        } else if (item.type === value2) {
          acc.items.push(item);
        }
        return acc;
      },
      { items: [] }
    );
  }, []);

  const displayBadgesIcon = mixedLineFiltered('badge', 'icon');
  const displayInfoText = mixedLineFiltered('separator', 'text');
  displayInfoText?.items.shift();

  return (
    <div className="VodOffersContentRight">
      <div className="VodSelectedOfferContainer">
        <ThumbnailPicture url={thumbnailUrl} />
        <div className="VodSelectedOfferBadgeContainer">
          <MixedLine mixedLine={displayBadgesIcon} badgeSize="small" />
          <MixedLine mixedLine={displayInfoText} badgeSize="small" />
        </div>
        <Typography variant="body3">Distribué par :</Typography>
        <div className="VodMetadataProvider">
          {providerLogos.map((logoUrl: string, key: number) => (
            <img className="Logo" src={logoUrl} key={key} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
}

export default VodProviderInfo;
