import React from 'react';
import { analyticsApi, useVodProviderLogo } from '@oqee/core';
import { Button, ListIcon, Metadata, MetadataProvider, Typography, castToMetadata } from '@oqee/ui';

import { useModal } from '../shared/Modal';

import './InformationCTA.css';

function InformationProvider({ content }) {
  const { entity_providers } = content;
  const providersLogo = useVodProviderLogo(content);

  if (!entity_providers || !providersLogo) return null;

  return <MetadataProvider text={entity_providers.text} providersLogo={providersLogo} mode="horizontal" />;
}

type InformationCTAProps = {
  program: any;
  rounded?: boolean;
  mainProgram?: any;
};

function InformationCTA({ mainProgram, program, rounded = false }: InformationCTAProps) {
  const { content } = program;
  const { meta = [], casting = [], title, entity_title } = content;
  const castingMeta = castToMetadata(casting);
  const isSerie = mainProgram?.content?.segmentation?.logical_type === 'serie';
  const isCollection = mainProgram?.content?.type === 'collection';
  const headerTitle = isSerie ? mainProgram.content.title : isCollection ? mainProgram.content.collection.title : title;

  const { Modal, openModal } = useModal(
    {
      title: headerTitle,
      description: (
        <span className="InformationModalDescription">
          {(isSerie || isCollection) && (
            <>
              <Typography variant="body1">{isSerie ? entity_title : isCollection ? title : null }</Typography>
              <br />
            </>
          )}
          <Typography variant="body1">{content.description}</Typography>
          <br />
          <Metadata variant="body1" metadata={[...meta, ...castingMeta]} />
          <br />
          <InformationProvider content={content} />
        </span>
      ),
      validateButtonLabel: 'Fermer',
      hasPadding: false
    },
    true
  );

  const handleClick = React.useCallback(() => {
    if(isSerie) {
      analyticsApi.logPageView('serie_more_infos');
    } else if(isCollection) {
      analyticsApi.logPageView('collection_more_infos');
    } else {
      analyticsApi.logPageView('multiprogram_more_infos');
    }
    openModal();
  }, [openModal]);

  return (
    <>
      <Button
        color="secondary"
        icon={ListIcon}
        rounded={rounded}
        onClick={handleClick}
        tooltip={rounded ? 'Voir le résumé' : undefined}
      >
        {!rounded && (
          <Typography variant="body1" bold>
            Information
          </Typography>
        )}
      </Button>
      <Modal />
    </>
  );
}

export default InformationCTA;
