import React from 'react';
import { useParams } from 'react-router-dom';
import { useVodProgramDetails } from '@oqee/core';

import PlayerPageLayout from '../../components/layout/PlayerPageLayout';
import VodTrailerPlayer from '../../components/player/TrailerPlayer';

type VodTrailerPlayerPageParams = {
  contentId: string;
};

function VodTrailerPlayerPage() {
  const { contentId } = useParams() as VodTrailerPlayerPageParams;
  const { isReady, programEntry } = useVodProgramDetails(Number(contentId), null, true);

  if (!isReady) return null;

  if (programEntry === undefined) return null;

  return (
    <PlayerPageLayout>
      <VodTrailerPlayer program={programEntry} />
    </PlayerPageLayout>
  );
}

export default VodTrailerPlayerPage;
