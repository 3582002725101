import React from 'react';
import { Typography } from '@oqee/ui';
import { PICTURE_FORMAT_WEB, formatPictureUrl, useVodOffersContext } from '@oqee/core';
import { APIVodOffer } from '@oqee/core/src/api/types/APIVodOffer';
import { APIVodProvider } from '@oqee/core/src/api/types/APIVodProvider';
import { useParams } from 'react-router-dom';

import { useWebStoreState } from '../../../store/webStoreUtils';

import SvodProviders from './SvodProviders';

import './SvodOffers.css';

type SvodStepPageParams = {
  portalId: string;
};

function SvodOffers() {
  const { VOD_PROVIDER_LOGO } = PICTURE_FORMAT_WEB;
  const params = useParams() as SvodStepPageParams;
  const { selectedOffer, onSelectOffer, offerListType } = useVodOffersContext();
  const providers: { [key: string]: APIVodProvider } = useWebStoreState(
    state => state.channel.servicePlan.vod_providers
  );

  const sortOffersPrice: APIVodOffer[] = React.useMemo(() => {
    if (selectedOffer.type) {
      if (!params.portalId) {
        return offerListType[selectedOffer.type].sort(
          (a: APIVodOffer, b: APIVodOffer) => a.price - b.price
        ) as APIVodOffer[];
      } else {
        return [offerListType[selectedOffer.type]] as APIVodOffer[];
      }
    }
    return [];
  }, [selectedOffer.type, params.portalId, offerListType]);

  React.useEffect(() => {
    if (!selectedOffer.svodProvider || selectedOffer.key === 0) {
      const offerPurchase = sortOffersPrice.length > 0 && sortOffersPrice[0];
      if (!selectedOffer.offerPurchase) {
        // from vod/portal_home
        if (params.portalId) {
          const svodProvider = Object.values(providers).find(provider => {
            return provider.portal_id === Number(params.portalId);
          });
          offerPurchase && onSelectOffer({ key: 0, svodProvider, offerPurchase: offerPurchase[0] });
        } else {
          // from home/vod
          const svodProvider = Object.values(providers).find(provider => {
            return provider.id === sortOffersPrice[0].provider_id;
          });
          offerPurchase && onSelectOffer({ key: 0, svodProvider, offerPurchase: offerPurchase[0] });
        }
      }
    } else {
      // when onBack from purchaseModal
      onSelectOffer({
        key: selectedOffer.key,
        svodProvider: selectedOffer.svodProvider,
        offerPurchase: selectedOffer.offerPurchase
      });
    }
  }, [selectedOffer.svodProvider, selectedOffer.key]);

  return (
    <div className="VodOffersContent">
      <div className="VodOffersContentLeft">
        <div className="VodOffersList">
          {sortOffersPrice.map((offer: APIVodOffer, key: number) => {
            return <SvodProviders offer={offer} keyItem={key} key={key} />;
          })}
        </div>
      </div>
      <div className="VodOffersContentRight">
        <div className="VodOffersContentRightContainer">
          {selectedOffer.svodProvider && (
            <>
              <img
                className="LogoProvider"
                src={formatPictureUrl(selectedOffer.svodProvider.logo_dark, VOD_PROVIDER_LOGO)}
                alt=""
              />
              <Typography variant="body2">{selectedOffer.svodProvider.subscription_description}</Typography>
              <Typography variant="body2">{selectedOffer.svodProvider.subscription_detail}</Typography>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SvodOffers;
