import React, { useState } from 'react';
import {
  ListItemSvodData,
  api,
  formatDateFullYear,
  formatEndMonthDateFullYear,
  usePurchaseCode,
  useToaster
} from '@oqee/core';
import { APIVodProvider } from '@oqee/core/src/api/types/APIVodProvider';
import clsx from 'clsx';
import { ChevronBasIcon, ChevronHautIcon } from '@oqee/ui';

import Typography from '../../Typography';
import PurchaseCodeModal from '../../PurchaseCodeModal';

import { useWebStoreState } from '../../../../store/webStoreUtils';

import './ListItemSvod.css';

interface ListItemSvodProps {
  item: ListItemSvodData;
  refreshSvod?: () => void;
}

function ListItemSvod({
  item: { key, title, creation_date, can_unsubscribe, unsubscribe_pending },
  refreshSvod
}: ListItemSvodProps) {
  const { addMessage } = useToaster();
  const { purchaseCode, codeLocked } = usePurchaseCode();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUnsubscribe, setIsUnsubscribe] = useState<boolean>(false);
  const providers: { [key: string]: APIVodProvider } = useWebStoreState(
    state => state.channel.servicePlan.vod_providers
  );

  const onClick = () => {
    !isUnsubscribe && setIsOpen(!isOpen);
  };

  const onCancel = () => {
    setIsUnsubscribe(false);
  };

  const onUnsubscribe = () => {
    setIsUnsubscribe(true);
  };

  const handleValidate = async (code: string) => {
    return await api.user.unSubscribeService(key, code).then(response => {
      if (!purchaseCode.codeAttemptLeft && !codeLocked && response?.success) {
        refreshSvod && refreshSvod();
        addMessage(
          `Résiliation confirmée ! Vous pouvez\n profiter de votre abonnement jusqu'à la\n fin de votre période active.`
        );
        onCancel();
      }
      return response;
    });
  };

  const ProviderDetail = (): React.ReactElement => {
    const getProviderInfo = Object.values(providers).find(provider => {
      return provider.service_name === key;
    });
    return (
      <Typography variant="body2" bold>
        {getProviderInfo?.subscription_detail}
      </Typography>
    );
  };

  return (
    <div className={clsx('ListItemSvod', { isUnsubscribe })} onClick={onClick}>
      <div className="ListItemSvodFlex">
        <Typography variant="body1" bold>
          {title}
        </Typography>
        <div className="ListItemSvodUnsubscribe">
          <Typography variant="body2" className="ListItemSubTitle">
            {unsubscribe_pending ? 'En cours de résiliation' : 'En cours'}
          </Typography>
          {isOpen ? <ChevronHautIcon className="Icon" /> : <ChevronBasIcon className="Icon" />}
        </div>
      </div>
      {isOpen && (
        <div className="ListItemSvodFlex">
          <Typography variant="body2" className="ListItemSubTitle">
            {unsubscribe_pending
              ? `Fin d'abonnement le ${formatEndMonthDateFullYear()}`
              : `Début d'abonnement le ${formatDateFullYear(creation_date)}`}
          </Typography>
          {can_unsubscribe && !unsubscribe_pending && (
            <div onClick={onUnsubscribe}>
              <Typography variant="body2">Résilier</Typography>
            </div>
          )}
        </div>
      )}
      {isUnsubscribe && (
        <PurchaseCodeModal
          title={`Résiliation ${title}`}
          subtitle={`Rentrez votre code d'achat à 4 chiffres pour vous désabonner de ce contenu :`}
          showLostCodeTip={true}
          onValidate={handleValidate}
          infoProvider={<ProviderDetail />}
          codeAttemptLeft={purchaseCode.codeAttemptLeft}
          codeLocked={codeLocked}
          onCancel={onCancel}
        />
      )}
    </div>
  );
}

export default ListItemSvod;
