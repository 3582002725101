import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SubscriptionsList from './SubscriptionsList';
import SubscriptionOffer from './SubscriptionOffer';
import SubscriptionsUnsubscribe from './SubscriptionsUnsubscribe';
import SubscriptionsSubscribe from './SubscriptionsSubscribe';
import SubscriptionsListSvod from './SubscriptionsList/SubscriptionsListSvod';

function Subscriptions() {
  return (
    <Routes>
      <Route path="tv" element={<SubscriptionsList />} />
      <Route path="svod" element={<SubscriptionsListSvod />} />
      <Route path="tv/offer/:offerType/:offerId" element={<SubscriptionOffer />} />
      <Route path="tv/offer/:offerType/:offerId/unsubscribe" element={<SubscriptionsUnsubscribe />} />
      <Route path="tv/offer/:offerType/:offerId/subscribe" element={<SubscriptionsSubscribe />} />
    </Routes>
  );
}

export default Subscriptions;
