import { ErrorModelState, useNpvrBumpQuota, useRecorder, useToaster } from '@oqee/core';
import React from 'react';
import { useWebStoreActions } from '../../store/webStoreUtils';
import Spinner from '../shared/Spinner/Spinner';
import Modal from '../shared/Modal';
import { useNavigate } from 'react-router-dom';

type LibraryUsageOverQuotaWarningProps = {
  error: ErrorModelState;
};

function LibraryUsageOverQuotaWarning({ error }: LibraryUsageOverQuotaWarningProps) {
  const resetError = useWebStoreActions(actions => actions.error.reset);

  const { data } = error;
  const { can_increase: canIncrease, next_quota: nextQuota } = data;

  const { bumpUsageQuota, newLimit } = useNpvrBumpQuota(nextQuota);
  const { isReady, status, fetchRecords } = useRecorder();
  const { addMessage } = useToaster();
  const { current_use: currentUse } = status;
  const navigate = useNavigate();

  const description = React.useMemo(() => {
    if (!isReady) return;

    // Quota maximum
    if (!canIncrease) {
      return `Vous allez atteindre la limite maximale d'enregistrement. Vous devez libérer de l'espace en effaçant vos anciens programmes.`;
    }
    // NPVR is activated, need to bump quota
    if (status.current_use) {
      return [
        status.duration ? [`Vous avez utilisé ${status.current_use}h d’enregistrements.`] : '',
        `Pour passer au palier d'enregistrement suivant de ${nextQuota / 60 / 60}h,
           continuer en cliquant sur OK.`
      ].join('\n');
    }
    // Message to activate recording
    return `Souhaitez vous activer la fonctionnalité enregistrement ?
              Vous disposerez de ${status.free / 60 / 60} heures d'enregistrement inclues dans votre offre.
              Au-delà, l'heure d'enregistrement supplémentaire est facturée ${status.price_per_hour_ct} ct / mois.`;
  }, [isReady, status, canIncrease, nextQuota]);

  const validateLabel = currentUse ? 'OK' : 'Activer';

  React.useEffect(() => {
    if (nextQuota === newLimit) {
      resetError();
      addMessage(
        status.current_use
          ? `Vous disposez maintenant de ${newLimit}h d'enregistrement`
          : `La fonctionnalité enregistrement est activée`
      );
    }
  }, [status, nextQuota, newLimit, resetError, addMessage]);

  React.useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  if (!isReady) return <Spinner testId="spinner_template" />;

  return (
    <Modal
      title={!canIncrease ? 'Limite maximale' : 'Abonnement Freebox'}
      description={description}
      onCancel={resetError}
      onValidate={() => {
        if (!canIncrease) {
          navigate('/my_list/records');
        }
        bumpUsageQuota();
      }}
      validateButtonLabel={!canIncrease ? `Gérer ma liste d'enregistrement` : validateLabel}
    />
  );
}

export default LibraryUsageOverQuotaWarning;
