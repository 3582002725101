import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import MainLayout from '../../components/layout/MainLayout';
import { HeaderNav } from '../../utils/webUtils';
import ContentRows, { CONTENT_ROWS_PLACEHOLDERS } from '../../components/content/ContentRows';
import useVodPortal from '../../hooks/useVodPortal';
import useVodHome from '../../hooks/useVodHome';
import ParentalGuard from '../../components/shared/ParentalGuard';
import NavHeader from '../../components/layout/NavHeader';

type VodPortalPageParams = {
  portalId: string;
};

type VodPortalPageProps = {
  activeHeaderNav?: HeaderNav;
  forcePortalId?: number;
};

function VodPortalPage(props: VodPortalPageProps) {
  const { activeHeaderNav = HeaderNav.VOD, forcePortalId } = props;
  const params = useParams() as VodPortalPageParams;
  const navigate: NavigateFunction = useNavigate();
  const handleBack = () => navigate(-1);
  const { isReady, sections } = useVodHome();
  const { portalId: strPortalId } = params;
  const portalId = Number(forcePortalId || strPortalId);
  const portalData = useVodPortal(portalId);

  const portalParental = React.useMemo(() => {
    if (!sections || !isReady) return;

    return sections
      .filter(section => section.type === 'portals')
      .reduce((flattenEntries, section) => flattenEntries.concat(section.entries), [])
      .map(portalEntry => portalEntry.portal)
      .find(portal => portal.id === portalId);
  }, [sections, portalId, isReady]);

  return (
    <MainLayout activeHeaderNav={activeHeaderNav} fbScreenName={`vod_portal_${portalId}`}>
      {portalData && activeHeaderNav === HeaderNav.VOD && (
        <NavHeader title={portalData.provider.name} onBack={handleBack} />
      )}
      <ParentalGuard isAdult={!!portalParental?.need_parental_code}>
        <ContentRows rows={portalData?.lines || CONTENT_ROWS_PLACEHOLDERS} showTileTitle={false} />
      </ParentalGuard>
    </MainLayout>
  );
}

export default VodPortalPage;
