import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  APIEpgEntry,
  MINUTE,
  formatDuration,
  formatTimeHM,
  useNewRecordDuration,
  useNewRecordEndTime,
  useNewRecordIsLive,
  useNewRecordMarginAfter,
  useNewRecordStartTime,
  useNpvrQuota,
  useNpvrQuotaUsage,
  useProgram,
  useProgramRecording
} from '@oqee/core';

import Step from '../../../../../shared/Step';
import Button from '../../../../../shared/Button';
import Typography from '../../../../../shared/Typography';
import { useWebStoreState } from '../../../../../../store/webStoreUtils';

import './ShakaPlayerUiLiveRecordModal.css';

const DATETIME_FORMAT: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric'
};

function ShakaPlayerUiLiveRecordDurationInfo() {
  const isLive: boolean = useNewRecordIsLive();
  const [startTime]: number[] = useNewRecordStartTime();
  const [endTime]: number[] = useNewRecordEndTime();
  const duration: number = useNewRecordDuration();

  const dateTime = new Date(startTime * 1000);
  const formatedDate: string = dateTime.toLocaleDateString('fr-FR', DATETIME_FORMAT);

  return (
    <div className="ShakaPlayerUiLiveRecordDurationInfo">
      <Typography variant="body2">Durée de l’enregistrement : {formatDuration(duration)}</Typography>
      <Typography variant="body2">Diffusé le {formatedDate}</Typography>
      <Typography variant="body2">
        {isLive ? 'Maintenant' : formatTimeHM(startTime)} - {formatTimeHM(endTime)}
      </Typography>
    </div>
  );
}

function ShakaPlayerUiLiveRecordMarginBefore({ step }) {
  const [marginAfter, setMarginAfter] = useNewRecordMarginAfter();

  return (
    <div className="ShakaPlayerUiLiveRecordMarginBefore">
      <Typography variant="body2">Marge après le programme</Typography>
      <Step
        label="minute"
        value={marginAfter / MINUTE}
        onChange={change => {
          setMarginAfter(marginAfter + step * change);
        }}
      />
    </div>
  );
}

function ShakaPlayerUiLiveRecordFormHint() {
  const quota = useNpvrQuota();
  const usage = useNpvrQuotaUsage(quota, undefined, 'web');

  return (
    <>
      <Typography variant="body3" color="grey-3">
        NB : La durée maximale d’un enregistrement unique est de 4 heures.
      </Typography>
      <Typography variant="body3" color="grey-3">
        {usage?.description}
      </Typography>
    </>
  );
}

interface ShakaPlayerUiLiveRecordButtonsProps {
  createRecord: () => void;
  closeModal: () => void;
}

function ShakaPlayerUiLiveRecordButtons({ createRecord, closeModal }: ShakaPlayerUiLiveRecordButtonsProps) {
  return (
    <div className="ShakaPlayerUiLiveRecordButtons">
      <Button label="Annuler" size="large" type="secondary" onClick={closeModal} />
      <Button label="Enregistrer" type="primary" onClick={createRecord} />
    </div>
  );
}

interface ShakaPlayerUiLiveRecordModalProps {
  closeModal: () => void;
}

function ShakaPlayerUiLiveRecordModal({ closeModal }: ShakaPlayerUiLiveRecordModalProps) {
  const currentEpgProgramEntry: APIEpgEntry | null = useWebStoreState(state => state.webPlayer.currentEpgProgramEntry);
  const program = useProgram(currentEpgProgramEntry);
  const { createRecord } = useProgramRecording({
    program,
    source: 'live',
    onCreated: closeModal
  });

  return (
    <ReactTooltip
      id="player-record-tooltip"
      isOpen={true}
      style={{ borderRadius: 8, zIndex: 1000 }}
      opacity={1}
      clickable
      noArrow
    >
      <div id="ShakaPlayerUiLiveRecordModalContent">
        <Typography variant="h4" bold>
          {program?.content?.title}
        </Typography>
        <ShakaPlayerUiLiveRecordDurationInfo />
        <ShakaPlayerUiLiveRecordMarginBefore step={5 * MINUTE} />
        <ShakaPlayerUiLiveRecordFormHint />
        <ShakaPlayerUiLiveRecordButtons createRecord={createRecord} closeModal={closeModal} />
      </div>
    </ReactTooltip>
  );
}

export default ShakaPlayerUiLiveRecordModal;
