import React from 'react';
import { useWebStoreActions } from '../../store/webStoreUtils';
import Modal from '../shared/Modal';
import { ErrorModelState } from '@oqee/core';

type ProgramAlreadyStartedWarningProps = {
  error: ErrorModelState;
};

function ProgramAlreadyStartedWarning({ error }: ProgramAlreadyStartedWarningProps) {
  const resetError = useWebStoreActions(actions => actions.error.reset);

  return (
    <Modal
      title={error?.title || 'Enregistrement'}
      description="Votre programme a déjà débuté, l’enregistrement sera incomplet."
      onValidate={resetError}
      validateButtonLabel={`D'accord`}
    />
  );
}

export default ProgramAlreadyStartedWarning;
