import React from 'react';
import { ListItemSvodData } from '@oqee/core';

import ListItem, { ListItemData } from './ListItem';
import ListItemSvod from './ListItem/ListItemSvod';

import './List.css';

interface ListSvodProps {
  svodItems: ListItemSvodData[];
  refreshSvod: () => void;
}

interface ListProps {
  items?: ListItemData[];
}

function ListSvod({ svodItems, refreshSvod }: ListSvodProps) {
  return (
    <div className="List">
      {svodItems.map((item: ListItemSvodData) => (
        <ListItemSvod key={`${item.id}_${item.title}`} item={item} refreshSvod={refreshSvod} />
      ))}
    </div>
  );
}

function List({ items = [] }: ListProps) {
  return (
    <div className="List">
      {items.map((item: ListItemData) => (
        <ListItem key={item.id} item={item} />
      ))}
    </div>
  );
}

export { List, ListSvod };
