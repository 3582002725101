import React from 'react';
import { Actions } from 'easy-peasy';
import { WebModel } from '../store/webModel';
import { webStore } from '../store/webStoreUtils';
import LibraryUsageOverQuotaWarning from '../components/errorScreen/LibraryUsageOverQuotaWarning';
import ProgramAlreadyStartedWarning from '../components/errorScreen/ProgramAlreadyStartedWarning';
import DuplicateRecordWarning from '../components/errorScreen/DuplicateRecordWarning';
import NpvrDeniedWarning from '../components/errorScreen/NpvrDeniedWarning';

const logoutAction = ({ navigate }) => {
  const { error } = webStore.getActions() as Actions<WebModel>;
  error.reset();
  navigate('/logout');
};

const reloadAction = () => {
  window.location.reload();
};

interface MenuItem {
  label: string;
  action: ({ navigate }) => void;
}

interface WebErrorRegistryEntry {
  title?: string;
  description?: string | ((arg0: any) => string);
  menuItems?: MenuItem[];
  isApplicationError?: boolean; // true if error is shown fullscreen
  isApplicationWarning?: boolean;
  component?: () => JSX.Element;

  /**  shownComponentInPlayer specifies where error message should be shown :
   * - PlayerErrorNeedSubscription : in subscription modal (like purchase code error)
   * - Modal : in simple modal
   * - SimplePlayerMessage : in player
   * - AdBlockPlayerMessage : "disable your adblock plugin" message
   */
  shownComponentInPlayer?: 'PlayerErrorNeedSubscription' | 'Modal' | 'SimplePlayerMessage' | 'AdBlockPlayerMessage';

  isRecoverable?: boolean; // do not stop video if isRecoverable===true
}

const errorRegistry: {
  [code: string]: WebErrorRegistryEntry;
} = {
  /**
   * Common errors
   */
  internal_error: {
    title: 'Erreur interne au serveur',
    description: 'Un problème interne est survenu, veuillez réessayer plus tard.',
    menuItems: [
      {
        label: 'Réessayer',
        action: reloadAction
      }
    ],
    isApplicationError: true
  },
  api_internal_error: {
    title: 'Erreur interne au serveur',
    description: 'Un problème interne est survenu, veuillez réessayer plus tard.',
    menuItems: [
      {
        label: 'Réessayer',
        action: reloadAction
      }
    ],
    isApplicationError: true
  },
  temporarily_unavailable: {
    title: 'Erreur interne',
    description: `Notre service est actuellement indisponible, nos équipes travaillent activement à la résolution de l'incident. Veuillez patienter et réessayer dans quelques instants.`,
    menuItems: [
      {
        label: 'Réessayer',
        action: reloadAction
      }
    ],
    isApplicationError: true
  },
  /**
   * Login errors
   */
  expired_oauth_state: {
    title: 'Relancer la connexion',
    description: 'La page de connexion a expiré, veuillez réessayer.',
    menuItems: [
      {
        label: 'Relancer la connexion',
        action: logoutAction
      }
    ],
    isApplicationError: true
  },
  unauthorized_tv_plan: {
    title: 'Votre offre OQEE',
    description: `Votre offre ne permet pas de profiter de l'application OQEE.`,
    menuItems: [
      {
        label: 'Se déconnecter',
        action: logoutAction
      }
    ],
    isApplicationError: true
  },
  no_tv_option: {
    title: 'Abonnement Freebox',
    description: `L'option TV de votre compte est désactivée, veuillez l'activer dans votre espace abonné.`,
    menuItems: [
      {
        label: 'Se déconnecter',
        action: logoutAction
      }
    ],
    isApplicationError: true
  },

  suspended_account: {
    title: 'Votre offre OQEE',
    description: `
      En raison d'un incident de paiement, votre service TV est restreint. 
      Merci de régulariser votre situation en procédant au règlement de vos factures.
      Une fois que les paiements seront enregistrés, 
      les services seront de nouveau totalement fonctionnels après un délai de 24h maximum.
    `,
    menuItems: [
      {
        label: 'Se déconnecter',
        action: logoutAction
      }
    ],
    isApplicationError: true
  },
  closed_account: {
    title: 'Votre offre OQEE',
    description: `Votre abonnement est actuellement inactif. Cette situation peut être due à un déménagement, une résiliation ou à un raccordement en cours.`,
    menuItems: [
      {
        label: 'Se déconnecter',
        action: logoutAction
      }
    ],
    isApplicationError: true
  },
  /*
   * User login, rights, rights_proxad API can trigger this error when customer is waiting for his internet line to be connected
   */
  pending_account: {
    title: 'Votre offre OQEE',
    description: `L’ouverture de votre ligne est actuellement en cours. Veuillez réessayer ultérieurement.`,
    menuItems: [
      {
        label: 'Se déconnecter',
        action: logoutAction
      }
    ],
    isApplicationError: true
  },
  /*
   * In case the token returned by PROXAD is corrupted or has no rights on OQEE
   */
  invalid_token: {
    title: "Erreur d'authentification",
    description: 'Votre compte n’a aucun accès aux droits TV',
    menuItems: [
      {
        label: 'Se déconnecter',
        action: logoutAction
      }
    ],
    isApplicationError: true
  },
  expired_token: {
    isRecoverable: true
  },
  // User tried to connect using a not eligible freemobile account
  not_eligible: {
    title: `Votre offre OQEE`,
    description: `Votre offre ne permet pas de profiter de l'application OQEE.`,
    menuItems: [
      {
        label: 'Se déconnecter',
        action: logoutAction
      }
    ],
    isApplicationError: true
  },
  not_found: {
    title: 'Contenu indisponible',
    description: `Ce contenu n'est pas disponible.`,
    menuItems: [
      {
        label: `Revenir à l'accueil`,
        action: ({ navigate }) => {
          const { error } = webStore.getActions() as Actions<WebModel>;
          error.reset();
          navigate('/home');
        }
      }
    ],
    isApplicationError: true
  },

  /**
   * Playback info errors
   */
  need_subscription: {
    shownComponentInPlayer: 'PlayerErrorNeedSubscription'
  },
  content_not_found: {
    title: 'Contenu introuvable',
    description: `Le contenu que vous demandez est introuvable ou n'est actuellement plus disponible.`,
    shownComponentInPlayer: 'Modal'
  },
  only_available_on_pop: {
    title: 'Information',
    description: `Ce contenu est disponible uniquement sur Freebox POP.`,
    shownComponentInPlayer: 'Modal'
  },
  not_implemented: {
    title: 'Attention',
    description: `Cette fonctionnalité n'existe pas, veuillez réessayer plus tard.`,
    shownComponentInPlayer: 'SimplePlayerMessage'
  },
  invalid_request: {
    title: 'Requête invalide',
    description: `Une erreur est intervenue sur nos serveurs.`,
    shownComponentInPlayer: 'SimplePlayerMessage'
  },
  csa_parental_lock: {
    title: 'Attention',
    description: `Ce programme est disponible entre 22h30 et 5h00`,
    shownComponentInPlayer: 'Modal'
  },

  /**
   * Licence errors : will be displayed as a message inside the player
   */
  too_many_streams: {
    title: 'Limite de connexions atteinte',
    description: ({ error }) =>
      `Dans le cadre de votre abonnement, vous êtes limité à ${
        error.data ? error.data.max_concurrent_streams : 5
      } connexions simultanées. Vous devez déconnecter un appareil et réessayer dans 30 minutes.`,
    shownComponentInPlayer: 'SimplePlayerMessage'
  },
  too_many_devices: {
    title: `Limite d'appareils atteinte`,
    description: ({ error }) =>
      `Dans le cadre de votre abonnement, vous êtes limité à ${
        error.data ? error.data.max_device_count : 150
      } appareils. Vous devez déconnecter un appareil et réessayer dans 30 minutes.`,
    shownComponentInPlayer: 'SimplePlayerMessage'
  },
  geo_blocked: {
    title: 'Erreur',
    description: `L'accès aux vidéos n'est pas autorisé depuis votre position géographique.`,
    shownComponentInPlayer: 'SimplePlayerMessage'
  },
  vpn_blocked: {
    title: 'Erreur',
    description: `L'accès aux vidéos n'est pas autorisé depuis un VPN.`,
    shownComponentInPlayer: 'SimplePlayerMessage'
  },

  /**
   * /user/channel_offers/{channel_id} errors
   */
  web_subscription_only: {
    title: 'Votre offre OQEE',
    description: 'Votre offre ne vous permet pas d’accéder à ce contenu.',
    shownComponentInPlayer: 'Modal'
  },
  already_subscribed: {
    title: 'Abonnement',
    description: 'Vous êtes déjà abonné à cette chaîne ou à ce groupe de chaînes',
    shownComponentInPlayer: 'Modal'
  },

  /**
   * Purchase & parental codes
   */
  purchase_error: {
    title: 'Paiement refusé',
    description: `Votre paiement à été refusé`
  },
  undefined_purchase_code: {
    title: 'Définir un code d’achat',
    description: `Veuillez définir un code d'achat dans les réglages.`,
    shownComponentInPlayer: 'Modal'
  },
  bad_purchase_code: {
    shownComponentInPlayer: 'PlayerErrorNeedSubscription'
  },
  code_rate_limit: {
    shownComponentInPlayer: 'PlayerErrorNeedSubscription'
  },
  invalid_purchase_code: {
    title: 'Nouveau code invalide',
    description: `Nouveau code invalide, les codes simples tels que '0000' ne sont pas acceptés.`
  },
  bad_confirmation_code: {
    title: 'Code de confirmation invalide',
    description: 'Le code de confirmation ne correspond pas au premier code rentré.'
  },
  undefined_parental_code: {
    title: 'Définir un code parental',
    description: 'Veuillez définir un code parental sur le site de Free.'
  },

  /**
   * Shaka player errors
   */
  unsupported_browser: {
    title: 'Alerte',
    description: `Navigateur non supporté`,
    shownComponentInPlayer: 'SimplePlayerMessage'
  },
  shaka_error_6001: {
    title: 'Alerte',
    description: `La lecture de ce flux vidéo n’est pas supportée par votre navigateur. `,
    shownComponentInPlayer: 'Modal'
  },
  shaka_error_4040: {
    // HLS_MSE_ENCRYPTED_MP2T_NOT_SUPPORTED
    title: 'Alerte',
    description: `La lecture de ce flux vidéo n’est pas supportée par le navigateur Safari. `,
    shownComponentInPlayer: 'Modal'
  },
  shaka_error_1002: {
    // HTTP_ERROR => must be some adblock
    shownComponentInPlayer: 'AdBlockPlayerMessage'
  },
  // recoverable playing errors : can happen during fast zapping (home) on safari
  shaka_error_6006: {
    // FAILED_TO_GENERATE_LICENSE_REQUEST
    isRecoverable: true
  },
  shaka_error_6007: {
    // LICENSE_REQUEST_FAILED
    isRecoverable: false
  },
  shaka_error_6008: {
    // LICENSE_RESPONSE_REJECTED
    isRecoverable: true
  },

  /**
   * NPVR
   */
  over_quota: {
    isRecoverable: true,
    component: () => {
      const { error } = webStore.getState();
      return <LibraryUsageOverQuotaWarning error={error} />;
    }
  },
  diffusion_ended: {
    isRecoverable: true,
    isApplicationWarning: true,
    title: 'Le programme sélectionné est déjà terminé'
  },
  max_duration_exceeded: {
    isRecoverable: true,
    isApplicationWarning: true,
    title: 'La durée de votre enregistrement doit être inférieure ou égale à 4h'
  },
  start_in_past: {
    isRecoverable: true,
    isApplicationWarning: true,
    title: 'Enregistrement impossible',
    description: 'Veuillez sélectionner une heure de début valide'
  },
  start_after_end: {
    isRecoverable: true,
    isApplicationWarning: true,
    title: 'Enregistrement impossible',
    description: "Veuillez sélectionner une heure de début valide inférieure à l'heure de fin"
  },
  record_too_long: {
    isRecoverable: true,
    isApplicationWarning: true,
    title: 'Enregistrement impossible',
    description: 'La durée de votre enregistrement doit être inférieure ou égale à 4h'
  },
  record_too_short: {
    isRecoverable: true,
    isApplicationWarning: true,
    title: 'Enregistrement impossible',
    description: 'La durée de votre enregistrement doit être supérieure à 1 minute'
  },
  record_program_already_started: {
    isRecoverable: true,
    component: () => {
      const { error } = webStore.getState();
      return <ProgramAlreadyStartedWarning error={error} />;
    }
  },
  duplicate_record: {
    isRecoverable: true,
    component: () => {
      const { error } = webStore.getState();
      return <DuplicateRecordWarning error={error} />;
    }
  },
  npvr_denied: {
    isRecoverable: true,
    component: () => {
      const { error } = webStore.getState();
      return <NpvrDeniedWarning error={error} />;
    }
  },
  not_available_for_this_channel: {
    isRecoverable: true,
    isApplicationWarning: true,
    title: `Cette fonctionnalité n’est pas disponible pour cette chaîne`
  }
};

export default errorRegistry;
export type { WebErrorRegistryEntry };
