import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { ChannelPlaybackInfo, useChannelEpg } from '@oqee/core';
import Logger from 'js-logger';

import ShakaPlayerUiFocus from './ShakaPlayerUiFocus';
import ShakaPlayerUiBackButton from './ShakaPlayerUiBackButton';
import { ShakaPlayerProps } from '../ShakaPlayer';
import ShakaPlayerUiLiveStartoverButtons from './ShakaPlayerUiLiveStartoverButtons';
import ShakaPlayerUiHeader from './ShakaPlayerUiHeader';
import ShakaPlayerUiTimeline from './ShakaPlayerUiTimeline';
import ShakaPlayerUiLangButton from './ShakaPlayerUiLangButton';
import PlayerDisclaimer from '../../PlayerDisclaimer';
import ShakaPlayerLiveTimelineUpdate from '../ShakaPlayerLiveTimelineUpdate';
import ShakaPlayerUi10sButtons from './ShakaPlayerUi10sButtons';
import ShakaPlayerUiLiveRecordButton from './ShakaPlayerUiLiveRecordButton/ShakaPlayerUiLiveRecordButton';
import ShakaPlayerUiVerticalVolume from './ShakaPlayerUiVerticalVolume/ShakaPlayerUiVerticalVolume';
import { AlwaysOnOverlayElementsPlayer } from '../../../shared/AlwaysOnOverlayElements';

const logger = Logger.get('ShakaPlayerUi');

interface ShakaPlayerUiProps extends ShakaPlayerProps {
  player: shaka.Player;
}

interface ShakaPlayerUiLiveProps extends ShakaPlayerProps {
  player: shaka.Player;
  playbackInfo: ChannelPlaybackInfo;
  timelineBar: HTMLDivElement;
  bottomBar: HTMLDivElement;
}

function ShakaPlayerUiLive(props: ShakaPlayerUiLiveProps) {
  const { player, videoEl, playbackInfo, startOver, timelineBar, bottomBar } = props;

  const currentTime: number = Math.round(Date.now() / 1000);
  const [programEntry] = useChannelEpg(playbackInfo.liveChannel.id, currentTime);

  // Enable record button only for channels with EPG until SMARTTV-1709 is done
  const enableRecordButton: boolean = programEntry?.live?.id !== null;

  return (
    <>
      <ShakaPlayerUiHeader programEntry={programEntry} videoEl={videoEl} />
      <ShakaPlayerUiLiveStartoverButtons
        playbackInfo={playbackInfo}
        videoEl={videoEl}
        startOver={startOver}
        timelineBar={timelineBar}
        player={player}
      />
      {enableRecordButton && <ShakaPlayerUiLiveRecordButton bottomBar={bottomBar} />}
      <ShakaPlayerLiveTimelineUpdate
        player={player}
        playbackInfo={playbackInfo}
        videoEl={videoEl}
        programEntry={programEntry}
      />
    </>
  );
}

function ShakaPlayerUi(props: ShakaPlayerUiProps) {
  const { player, uiContainerEl, videoEl, playbackInfo } = props;
  const { broadcastType } = playbackInfo;
  const isLive = broadcastType === 'live';

  const timelineBar: HTMLDivElement = makeDiv('timeline-bar');
  const bottomBar: HTMLDivElement = makeDiv('bottom-bar');

  const [uiOverlay, setUiOverlay] = React.useState<shaka.ui.Overlay | null>(null);

  /* Creates Shaka UI overlay */
  React.useEffect(() => {
    const _uiOverlay = new shaka.ui.Overlay(player, uiContainerEl, videoEl);
    setUiOverlay(_uiOverlay);

    // TODO: this part is throwing release is undefined exception, make sure to add component release function before destroying
    return () => {
      _uiOverlay?.destroy().catch(error => {
        if (error.message.indexOf('release') > -1) {
          logger.warn(error.message);
        }
        return error;
      });
    };
  }, [player, uiContainerEl, videoEl, broadcastType]);

  /*
   * Configure overlay, once custom elements have been registered
   */
  React.useEffect(() => {
    if (!uiOverlay) return;
    const isSeekEnabled: boolean = !isLive || !!playbackInfo?.liveChannel?.isStartOverAllowed;

    uiOverlay.configure({
      controlPanelElements: [
        'oqee_back_button',
        'airplay',
        'cast',
        'picture_in_picture',
        'play_pause',
        'oqee_10s_back_button',
        'oqee_10s_fwd_button',
        'oqee_vertical_volume',
        'fullscreen',
        'oqee_lang_button',
        ...(isLive ? ['oqee_goToStart_button', 'oqee_goToLive_button', 'oqee_record_button'] : [])
      ],
      addSeekBar: true,
      singleClickForPlayAndPause: isSeekEnabled,
      enableKeyboardPlaybackControls: isSeekEnabled,
      keyboardSeekDistance: 10
    });

    // disable play button if startover is not allowed on current live channel
    const playButton = document.getElementsByClassName('shaka-small-play-button').item(0) as HTMLButtonElement | null;
    if (playButton && !isSeekEnabled) {
      playButton.disabled = true;
      playButton.classList.add('disabledGray');
      playButton.setAttribute('data-tooltip-id', 'simple-tooltip');
      playButton.setAttribute('data-tooltip-content', 'Fonctionnalité désactivée');
    }

    // append timelineContainer and bottomBar as children of shakaBottomControls
    const shakaBottomControls: Element | null = document.getElementsByClassName('shaka-bottom-controls').item(0);
    if (shakaBottomControls) {
      shakaBottomControls.appendChild(timelineBar);
      shakaBottomControls.appendChild(bottomBar);
    }
  }, [uiOverlay, playbackInfo.manifestUrl]);

  if (!uiOverlay) {
    return null;
  }

  return (
    <>
      <ShakaPlayerUiFocus {...props} uiOverlay={uiOverlay} />
      <ShakaPlayerUiBackButton {...props} />
      <ShakaPlayerUi10sButtons {...props} />
      <ShakaPlayerUiVerticalVolume />
      {isLive && (
        <ShakaPlayerUiLive {...props} timelineBar={timelineBar} bottomBar={bottomBar} playbackInfo={playbackInfo} />
      )}
      <ShakaPlayerUiTimeline timelineBar={timelineBar} playbackInfo={playbackInfo} />
      <ShakaPlayerUiLangButton bottomBar={bottomBar} player={player} />
      <PlayerDisclaimer />
      <AlwaysOnOverlayElementsPlayer />
    </>
  );
}

function makeDiv(className: string): HTMLDivElement {
  const element: HTMLDivElement = document.createElement('div');
  element.classList.add(className);
  return element;
}

export default ShakaPlayerUi;
