import React, { useMemo } from 'react';
import { BasketIcon, Button, CroixPleinIcon, PlayIcon, Typography, VodPleineIcon } from '@oqee/ui';
import { useVodProgramDetails } from '@oqee/core';
import { Link, useParams } from 'react-router-dom';
import { APIVodProvider } from '@oqee/core/src/api/types/APIVodProvider';

import VodOffersGuard from '../vod';
import { useWebStoreState } from '../../store/webStoreUtils';

type VodPageParams = {
  portalId: string;
};

type VodCTAProps = {
  program: any;
  rounded?: boolean;
  isResumable?: boolean;
  onResumableDelete?: () => void;
};

function VodCTA({ program, rounded = false, isResumable = false, onResumableDelete }: VodCTAProps) {
  const { content_id, ordered_offer_groups } = program.content;
  const { portalId } = useParams<VodPageParams>();
  const purchasedVod = useVodProgramDetails(content_id, null, false, ordered_offer_groups);
  const [showVodOffers, setShowVodOffers] = React.useState<boolean>(false);
  const tvRights = useWebStoreState(state => state.auth.tvRights.payload);
  const providers: { [key: string]: APIVodProvider } = useWebStoreState(
    state => state.channel.servicePlan.vod_providers
  );

  const renderSvodParams = () => {
    // vod/portal_home
    if (portalId) {
      return `/svod/portal/${portalId}/${content_id}/play`;
    }
    // home/vod - oqee_cine
    const providerPortal = Object.values(providers).filter(provider => {
      return program.content.provider_ids.find((id: number) => {
        return provider.id === id;
      });
    });
    const serviceProviderPortal = providerPortal?.find(portal => {
      return tvRights && tvRights.services.find((service: string) => service === portal.service_name);
    });
    if (serviceProviderPortal) {
      return `/svod/portal/${serviceProviderPortal.portal_id}/${content_id}/play`;
    }
    return '';
  };

  const [isPlayable, playbackUrl, trailerUrl] = useMemo(() => {
    const programEntry = purchasedVod?.programEntry;
    const content = program?.content;
    const trailer_ids = content?.trailer_ids;
    const provider_ids = content?.provider_ids;

    return [
      programEntry?.isPlayable,
      programEntry?.purchase?.id ? `/tvod/${programEntry.purchase.id}/play` : renderSvodParams(),
      trailer_ids?.length > 0 && provider_ids?.length > 0 ? `/vod/${provider_ids[0]}/${content_id}/trailer` : undefined
    ];
  }, [purchasedVod, program, renderSvodParams]);

  return (
    <>
      {isPlayable ? (
        <Link to={playbackUrl}>
          <Button
            color="primary"
            rounded={rounded}
            icon={PlayIcon}
            iconColor="info"
            tooltip={rounded ? 'Lecture' : undefined}
          >
            {!rounded && (
              <Typography variant="body1" bold>
                Lecture
              </Typography>
            )}
          </Button>
        </Link>
      ) : (
        <VodOffersGuard
          onCancel={() => setShowVodOffers(false)}
          onSuccess={() => setShowVodOffers(false)}
          displayOffers={showVodOffers}
          program={program}
          vodProgramDetails={purchasedVod}
        >
          <Button
            onClick={() => setShowVodOffers(true)}
            color="primary"
            icon={BasketIcon}
            rounded={rounded}
            iconColor="info"
            tooltip={rounded ? 'Accéder au contenu' : undefined}
          >
            {!rounded && (
              <Typography variant="body1" bold>
                Accéder au contenu
              </Typography>
            )}
          </Button>
        </VodOffersGuard>
      )}
      {isResumable && (
        <Button
          data-test-id="cta-vod-resume-delete"
          icon={CroixPleinIcon}
          tooltip="Retirer de la ligne"
          onClick={onResumableDelete}
        />
      )}
      {trailerUrl && (
        <Link to={trailerUrl}>
          <Button color="secondary" icon={VodPleineIcon} rounded={rounded} iconColor="info" tooltip="Bande-annonce" />
        </Link>
      )}
    </>
  );
}

export default VodCTA;
