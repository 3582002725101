import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { usePurchaseCode, useSubscriptionManager, formatPrice } from '@oqee/core';

import { HeaderNav } from '../../../../utils/webUtils';

import MainLayout from '../../../../components/layout/MainLayout';
import PurchaseCodeModal from '../../../../components/shared/PurchaseCodeModal';
import Spinner from '../../../../components/shared/Spinner';


function SubscriptionsSubscribe() {
  const navigate: NavigateFunction = useNavigate();
  const { offerType, offerId } = useParams();
  const { purchaseCode, codeLocked, resetCodeAttempt } = usePurchaseCode();
  const subscriptionManager = useSubscriptionManager(offerType, Number(offerId));
  const { currentOffer, currentOfferedSubscribed, subscribeOffer, reset } = subscriptionManager;
  const [isSubscribing, setIsSubscribing] = useState(false);

  useEffect(() => {
    return resetCodeAttempt;
  }, []);

  useEffect(() => {
    // When subscribed go back to list
    if (currentOfferedSubscribed) {
      navigate('/settings/subscriptions/tv');
    }
  }, [currentOfferedSubscribed]);

  useEffect(() => {
    // Fetched and not exist, go back to list
    if (currentOffer === undefined) {
      return navigate('/settings/subscriptions/tv');
    }
  }, [currentOffer]);

  if (!currentOffer || isSubscribing) {
    return <Spinner />;
  }

  const { title, price } = currentOffer;
  const subtitle = `Saisissez votre code d'achat pour souscrire à ${formatPrice(price, '€')}/mois, sans engagement`;

  const handleValidate = purchaseCode => {
    setIsSubscribing(true);

    const offer = {
      id: Number(offerId),
      type: offerType
    }
    return subscribeOffer(offer, purchaseCode)
      .then(response => {
        setIsSubscribing(false);
        return response;
      })
      .catch(() => setIsSubscribing(false));
  };

  const onCancel = () => navigate(-1);

  return (
    <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>
      <PurchaseCodeModal
        title={title}
        subtitle={subtitle}
        showLostCodeTip
        codeLocked={codeLocked}
        onValidate={handleValidate}
        onSuccess={reset}
        codeAttemptLeft={purchaseCode.codeAttemptLeft}
        onCancel={onCancel}
      />
    </MainLayout>
  );
}

export default SubscriptionsSubscribe;
