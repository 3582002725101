import { Button, MinusIcon, PlusIcon, Typography } from '@oqee/ui';
import React from 'react';

import './Step.css';

type StepProps = {
  label: string;
  value?: number;
  onChange?: (value) => void;
};

function Step({ label, value = 0, onChange }: StepProps) {
  const handleDecrease = React.useCallback(() => {
    onChange && onChange(-1);
  }, [value, onChange]);

  const handleIncrease = React.useCallback(() => {
    onChange && onChange(+1);
  }, [value, onChange]);

  const text = React.useMemo(
    function () {
      if (!label) return value || 0;
      return `${value} ${label}${value > 1 ? 's' : ''}`;
    },
    [value, label]
  );

  return (
    <div className="Step">
      <Button icon={MinusIcon} size="small" rounded onClick={handleDecrease} />
      <Typography>{text}</Typography>
      <Button icon={PlusIcon} size="small" rounded onClick={handleIncrease} />
    </div>
  );
}

export default Step;
