import React from 'react';
import MainLayout from '../MainLayout';
import ContentGrid from '../../content/ContentGrid';
import Breadcrumb from '../../shared/Breadcrumb';
import ButtonBack from '../../shared/ButtonBack';

function VodPortalLayout({ title, entries }) {
  return (
    <MainLayout>
      <Breadcrumb>
        <ButtonBack title={title} />
      </Breadcrumb>
      <ContentGrid entries={entries} showTileTitle className="ContentGrid--vod" />
    </MainLayout>
  );
}

export default VodPortalLayout;
