import React from 'react';
import { APIEpgEntry, zeroPad } from '@oqee/core';
import { useChannelSingleEpg, FormattedChannel } from '@oqee/core';
import { useProgram } from '@oqee/core';
import Typography from '../../../components/shared/Typography';

import './ChannelGridItem.css';
import { Link } from 'react-router-dom';
import { useWebStoreState } from '../../../store/webStoreUtils';
import ContentThumbnail from '../../../components/content/ContentThumbnail';

interface ChannelGridItemProgramProps {
  programEntry: APIEpgEntry;
  channel: FormattedChannel;
}

function ChannelGridItemProgram({ programEntry, channel }: ChannelGridItemProgramProps) {
  const program = useProgram(programEntry);

  const [channelName, channelNumberPad, [start, end], title, subtitle] = React.useMemo(() => {
    return [
      channel.name || '',
      zeroPad(channel.number),
      program.getTimeRange(),
      program.content.title,
      program.content.sub_title
    ];
  }, [program]);

  const hasEPG = end || start > 0;
  const programUrl = `${channel.id}${program.isLocked && !program.promoToken && hasEPG ? '/detail' : '/play'}`;

  return (
    <Link to={programUrl} data-test-id={`channel-grid-item-program-${channelNumberPad}`}>
      <div className="ChannelGridItem">
        <div className="ChannelGridItemThumbnail">
          <ContentThumbnail entry={programEntry} showCircularProgress nolink />
        </div>
        <div className="ChannelGridItemTitlesContainer">
          <Typography variant="body2" color="grey-4" className="ChannelNumber">
            {channelNumberPad} - {channelName}
          </Typography>
          <Typography
            variant="body1"
            bold
            className="ProgramTitle"
            data-tooltip-id="simple-tooltip"
            data-tooltip-content={title}
            data-tooltip-place="top-start"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            bold
            className="ProgramSubtitle"
            data-tooltip-id="simple-tooltip"
            data-tooltip-content={subtitle}
            data-tooltip-place="top-start"
          >
            {subtitle}
          </Typography>
        </div>
      </div>
    </Link>
  );
}

function ChannelGridItemPlaceholder() {
  return (
    <div className="ChannelGridItem">
      <div className="ChannelGridItemThumbnail"></div>
    </div>
  );
}

interface ChannelGridItemProps {
  channel: FormattedChannel;
}

function ChannelGridItem({ channel }: ChannelGridItemProps) {
  const timestamp: number = useWebStoreState(state => state.channel.timestamp);
  const programEntry: APIEpgEntry = useChannelSingleEpg(channel.id, timestamp);

  return <ChannelGridItemProgram programEntry={programEntry} channel={channel} />;
}

export { ChannelGridItem, ChannelGridItemPlaceholder };
