import React, { useState } from 'react';
import {
  PICTURE_FORMAT_WEB,
  SelectedOfferType,
  VodOffersContext,
  api,
  formatPictureUrl,
  formatPrice,
  usePurchaseCode,
  useToaster,
  useVodPurchase
} from '@oqee/core';
import { Typography } from '@oqee/ui';
import { APIVodOffer } from '@oqee/core/src/api/types/APIVodOffer';

import PurchaseCodeModal from '../shared/PurchaseCodeModal';
import VodOffers from './VodOffers';

import './VodOffersGuard.css';

interface VodOffersGuardProps extends React.PropsWithChildren {
  onSuccess: () => void;
  onCancel: () => void;
  displayOffers: boolean;
  program: any;
  vodProgramDetails: any;
}

function VodOffersGuard({
  onSuccess,
  onCancel,
  displayOffers,
  program,
  vodProgramDetails,
  children
}: VodOffersGuardProps) {
  const { purchaseCode, codeLocked } = usePurchaseCode();
  const { addMessage } = useToaster();
  const [step, setStep] = React.useState<number>(0);
  const { VOD_PROVIDER_LOGO } = PICTURE_FORMAT_WEB;
  const [selectedOffer, setSelectedOffer] = useState<SelectedOfferType>({
    key: 0,
    type: undefined,
    svodProvider: undefined,
    offerProvider: undefined,
    offerPurchase: undefined
  });
  const { title, content_id: contentId } = program.content;
  const { purchaseOffer } = useVodPurchase(contentId, selectedOffer.offerPurchase);
  const offerList = vodProgramDetails.programEntry.offers;

  const isPurchase: boolean = React.useMemo(() => {
    return (step === 2 && selectedOffer.type === 'SVOD') || step === 3;
  }, [step, selectedOffer]);

  const offerListType = React.useMemo(() => {
    return offerList.reduce((acc, item: APIVodOffer) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {});
  }, [offerList]);

  const onStep = React.useCallback(
    (value: number): void => {
      setStep(value);
    },
    [setStep]
  );

  const onNext = React.useCallback(() => {
    setStep(step + 1);
    onSelectOffer({ key: 0 });
  }, [step, setStep]);

  const onBack = React.useCallback(() => {
    setStep(step - 1);
    onSelectOffer({ key: 0 });
  }, [step, setStep]);

  const displayToaster = (response: any) => {
    if (!purchaseCode.codeAttemptLeft && !codeLocked && response?.success) {
      addMessage(`Votre collection s'agrandit, excellent\n choix ! N'attendez plus pour\n commencer votre lecture.`);
      onCancel();
    }
  };

  const handleValidate = async (code: string) => {
    if (selectedOffer.type === 'SVOD') {
      return await api.user.subscribeService(selectedOffer.svodProvider?.service_name, code).then(response => {
        displayToaster(response);
        return response;
      });
    } else {
      return await purchaseOffer({ code }).then(response => {
        displayToaster(response);
        return response;
      });
    }
  };

  const onSelectOffer = React.useCallback((offer: SelectedOfferType) => {
    setSelectedOffer((prevOffer: SelectedOfferType) => ({
      ...prevOffer,
      ...offer
    }));
  }, []);

  const DisplayTitle = (): React.ReactElement => {
    return (
      <Typography variant="body1">
        {selectedOffer.type === 'SVOD'
          ? `S'abonner à ${selectedOffer?.svodProvider?.name} - ${selectedOffer?.svodProvider?.subscription_detail}`
          : `${selectedOffer?.offerPurchase?.name} - ${formatPrice(selectedOffer?.offerPurchase?.price, '€')}`}
      </Typography>
    );
  };

  const DisplayStepFooter = (): React.ReactElement => {
    return (
      <Typography variant="body1">
        Étape {step}/{selectedOffer.type === 'SVOD' ? 2 : 3}
      </Typography>
    );
  };

  const DisplayInfoProvider = (): React.ReactElement => {
    return (
      <div className="VodOffersGuardInfoProviderContainer">
        <DisplayTitle />
        <div className="VodOffersGuardInfoProviderFlex">
          <Typography variant="body3">Distribué par :</Typography>
          <img
            className="Logo"
            src={formatPictureUrl(
              selectedOffer.type === 'SVOD'
                ? selectedOffer?.svodProvider?.logo_dark
                : selectedOffer?.offerProvider?.logo_dark,
              VOD_PROVIDER_LOGO
            )}
            alt=""
          />
        </div>
      </div>
    );
  };

  const context = React.useMemo(
    () => ({
      program,
      offerListType,

      onSuccess,
      onCancel,

      step,
      onStep,
      onNext,
      onBack,

      selectedOffer,
      onSelectOffer
    }),
    [onSuccess, onCancel, program, offerListType, step, onStep, selectedOffer, onSelectOffer, onNext, onBack]
  );

  return (
    <VodOffersContext.Provider value={context}>
      {children}
      {displayOffers && (
        <div className="VodOffersGuard">
          {isPurchase ? (
            <PurchaseCodeModal
              title={title}
              subtitle="Rentrez votre code d’achat à 4 chiffres pour acheter ce contenu :"
              showLostCodeTip={true}
              codeAttemptLeft={purchaseCode.codeAttemptLeft}
              codeLocked={codeLocked}
              onValidate={handleValidate}
              onCancel={onCancel}
              onClick={onBack}
              onStep={onStep}
              stepFooter={<DisplayStepFooter />}
              infoProvider={<DisplayInfoProvider />}
              cgv={selectedOffer?.offerProvider?.cgu_url}
            />
          ) : (
            <VodOffers />
          )}
        </div>
      )}
    </VodOffersContext.Provider>
  );
}

export default VodOffersGuard;
