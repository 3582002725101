import React from 'react';
import { PICTURE_FORMAT_WEB, argb2rgba, formatPictureUrl, formatPrice, useVodOffersContext } from '@oqee/core';
import { Typography } from '@oqee/ui';
import { APIVodOffer } from '@oqee/core/src/api/types/APIVodOffer';
import { APIVodProvider } from '@oqee/core/src/api/types/APIVodProvider';
import clsx from 'clsx';

import Breadcrumb from '../../shared/Breadcrumb';
import ButtonBack from '../../shared/ButtonBack';
import Spinner from '../../shared/Spinner/Spinner';
import VodProviderInfo from '../VodOffersProviderInfo';

import { useWebStoreState } from '../../../store/webStoreUtils';

import './TvodOffers.css';

function OfferStep() {
  const providers: { [key: string]: APIVodProvider } = useWebStoreState(
    state => state.channel.servicePlan.vod_providers
  );
  const { onBack, offerListType, selectedOffer, onSelectOffer } = useVodOffersContext();
  const { VOD_PROVIDER_LOGO } = PICTURE_FORMAT_WEB;
  const setProviders = new Set();

  const sortOffersPrice: APIVodOffer[] = React.useMemo(() => {
    return (
      selectedOffer.type &&
      offerListType[selectedOffer.type]
        .sort((a: APIVodOffer, b: APIVodOffer) => a.price - b.price)
        .filter((offer: APIVodOffer) => {
          const duplicate = setProviders.has(offer.provider_id);
          setProviders.add(offer.provider_id);
          return !duplicate;
        })
    );
  }, [offerListType, selectedOffer.type]);

  const displayProviders = (offerItem: APIVodOffer, key: number) => {
    const vodProvider = Object.values(providers).find(provider => {
      return provider.id === offerItem.provider_id;
    });
    const offerProvider = vodProvider as APIVodProvider;
    const { logo_dark, name } = offerProvider;

    return selectedOffer ? (
      <div
        onClick={() => onSelectOffer({ key, offerProvider })}
        key={offerProvider.id}
        className={clsx('VodOffersListElement', {
          checked: selectedOffer.key === key
        })}
      >
        <div className="VodOffersListElementContainer">
          <div className="VodOffersListElementProvider">
            <img className="LogoProvider" src={formatPictureUrl(logo_dark, VOD_PROVIDER_LOGO)} alt="" />
            <div>
              <Typography variant="body2">{name}</Typography>
              <Typography variant="body3" style={{ color: argb2rgba('#FFBDBDBD') }}>
                À partir de {formatPrice(offerItem.price, '€')}
              </Typography>
            </div>
          </div>
          <div>
            <img src={`/img/checkbox_${selectedOffer.key === key ? 'checked' : 'unchecked'}.svg`} alt="" />
          </div>
        </div>
      </div>
    ) : (
      <Spinner testId="offer_step_loading" />
    );
  };

  React.useEffect(() => {
    if ((!selectedOffer.offerProvider || selectedOffer.key === 0) && sortOffersPrice.length > 0) {
      const offerProvider = Object.values(providers).find(provider => {
        return provider.id === sortOffersPrice[0].provider_id;
      });
      onSelectOffer({ key: 0, offerProvider });
    } else {
      onSelectOffer({ key: selectedOffer.key, offerProvider: selectedOffer.offerProvider });
    }
  }, [selectedOffer.offerProvider, selectedOffer.key]);

  return (
    <div className="VodOffersContent">
      <div className="VodOffersContentLeft">
        <Breadcrumb>{<ButtonBack onClick={onBack} />}</Breadcrumb>
        <div className="VodOffersList">
          {sortOffersPrice.map((offer: APIVodOffer, key: number) => {
            return displayProviders(offer, key);
          })}
        </div>
      </div>
      <VodProviderInfo />
    </div>
  );
}

export default OfferStep;
