import React from 'react';

import Toaster from '../Toaster/Toaster';
import SimpleTooltip from '../SimpleTooltip';
import useFullscreen from '../../../hooks/useFullscreen';
import ApplicationError from '../../application/ApplicationError';

/**
 * AlwaysOnOverlayElements component contains overlay elements (modals, toasters, tooltips..)
 * that can be displayed at any time, i.e. when player is in full screen or not.
 * In order to do this they need to be included either :
 *
 * - when not in fullscreen, using <AlwaysOnOverlayElementsRoot /> at the app root
 * - when in fullscreen, using <AlwaysOnOverlayElementsPlayer /> in the player container (which contains the only elements that can be shown in fullscreen)
 */

function AlwaysOnOverlayElements() {
  return (
    <>
      <ApplicationError />
      <Toaster className="ToasterApp" />
      <SimpleTooltip id="simple-tooltip" />
    </>
  );
}

function AlwaysOnOverlayElementsRoot() {
  const isFullscreen: boolean = useFullscreen();

  return !isFullscreen ? <AlwaysOnOverlayElements /> : null;
}

function AlwaysOnOverlayElementsPlayer() {
  const isFullscreen: boolean = useFullscreen();

  return isFullscreen ? <AlwaysOnOverlayElements /> : null;
}

export { AlwaysOnOverlayElementsRoot, AlwaysOnOverlayElementsPlayer };
