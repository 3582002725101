import React from 'react';
import clsx from 'clsx';
import { APIUserAvatarAgeRange, APIUserAvatarShape, useAccountProfile } from '@oqee/core';
import { NavigateFunction, useLocation, useNavigate, Location, useParams } from 'react-router-dom';

import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import NavHeader from '../../../../components/layout/NavHeader';
import Typography from '../../../../components/shared/Typography';
import Button from '../../../../components/shared/Button';

import './ProfilePicture.css';

interface AvatarShapeProps {
  avatarShape: APIUserAvatarShape;
  avatarTone: string;
  onSelect: (avatarShapeKey: string) => void;
  selected: boolean;
}

function AvatarShape({ avatarShape, avatarTone, onSelect, selected }: AvatarShapeProps) {
  const { picture } = avatarShape;
  const { getAvatarUrl } = useAccountProfile();
  const pictureUrl = getAvatarUrl({
    url: picture,
    avatar_tone: `#${avatarTone.slice(1)}`
  });

  return (
    <div className={clsx('AvatarShape', { selected })} onClick={() => onSelect(avatarShape.key)}>
      <img src={pictureUrl} alt="" />
    </div>
  );
}

interface AvatarShapeGridProps {
  ageRangeAvatars: APIUserAvatarAgeRange;
  avatarTone: string;
  selectedShapeKey: string | null;
  onSelect: (avatarShapeKey: string) => void;
}

function AvatarShapeGrid({ ageRangeAvatars, avatarTone, selectedShapeKey, onSelect }: AvatarShapeGridProps) {
  const avatarShapes = ageRangeAvatars.avatar_shapes;

  return (
    <div className="AvatarShapeGrid">
      {avatarShapes.map((avatarShape, index) => (
        <AvatarShape
          key={index}
          avatarShape={avatarShape}
          avatarTone={avatarTone}
          selected={selectedShapeKey === avatarShape.key}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
}

interface AvatarToneListProps {
  tones: string[];
  selectedTone: string;
  onSelect: (tone: string) => void;
}

function AvatarToneList({ tones, selectedTone, onSelect }: AvatarToneListProps) {
  return (
    <div className="AvatarToneList">
      {tones.map((tone, index) => (
        <span
          key={index}
          className={clsx('AvatarToneListItem', { selected: selectedTone === tone })}
          style={{ backgroundColor: tone }}
          onClick={() => onSelect(tone)}
        />
      ))}
    </div>
  );
}

type ProfilePictureParams = {
  profileId: string | undefined;
  ageRange: string;
};

function ProfilePicture() {
  const { profileId, ageRange } = useParams() as ProfilePictureParams;
  const location: Location = useLocation();
  const state = location.state ?? {};
  const navigate: NavigateFunction = useNavigate();
  const { avatars, getProfile } = useAccountProfile();
  const currentProfile = getProfile(profileId);

  const ageRangeAvatars: APIUserAvatarAgeRange = avatars?.age_ranges?.find(a => a.key === ageRange);

  const initialAvatarTone: string = state?.avatarTone
    ? `#${state.avatarTone}`
    : currentProfile
    ? currentProfile.avatar_tone
    : avatars?.tones?.[0];
  const [selectedAvatarTone, setSelectedAvatarTone] = React.useState<string>(initialAvatarTone);

  const initialAvatarShapeKey: string | null = state?.avatarShapeKey ?? currentProfile?.avatar_shape ?? null;
  const [selectedAvatarShapeKey, setSelectedAvatarShapeKey] = React.useState<string | null>(initialAvatarShapeKey);

  const handleBack = () => {
    navigate('..', {
      relative: 'path',
      state: {
        ageRange
      }
    });
  };

  const handleContinue = () => navigate(`${selectedAvatarTone.slice(1)}/${selectedAvatarShapeKey}`);

  return (
    <UserProfileLayout>
      <div className="ProfilePicture">
        <NavHeader onBack={handleBack} />

        <div className="ProfilePictureTitle">
          <Typography variant="h1">Votre avatar</Typography>
        </div>

        <AvatarToneList tones={avatars.tones} selectedTone={selectedAvatarTone} onSelect={setSelectedAvatarTone} />

        <AvatarShapeGrid
          ageRangeAvatars={ageRangeAvatars}
          avatarTone={selectedAvatarTone}
          selectedShapeKey={selectedAvatarShapeKey}
          onSelect={setSelectedAvatarShapeKey}
        />

        <div className="ProfilePictureContinueButton">
          <Button
            label="Continuer"
            type="primary"
            onClick={handleContinue}
            disabled={!selectedAvatarTone || !selectedAvatarShapeKey}
          />
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default ProfilePicture;
