import React from 'react';
import { useProgram } from '@oqee/core';

import Breadcrumb from '../../../../shared/Breadcrumb';
import CircularProgress from '../../../../shared/CircularProgress';
import Typography from '../../../../shared/Typography';

import './ShakaPlayerUiHeader.css';

interface ShakaPlayerUiHeaderProps {
  programEntry: any;
  videoEl: HTMLVideoElement;
}

function ShakaPlayerUiHeader({ videoEl, programEntry }: ShakaPlayerUiHeaderProps) {
  const currentTime = videoEl.currentTime || Date.now() / 1000;
  const program = useProgram(programEntry);
  const { contentType, getIcon, channel } = program;
  const { start, end, title, sub_title } = programEntry.live;
  const channelColor = channel?.color ?? '#888';

  return (
    <div className="ShakaPlayerUiHeaderContainer">
      <Breadcrumb className="ShakaPlayerUiHeader">
        <div className="CircularProgress">
          <CircularProgress
            color={channelColor}
            currentTime={currentTime}
            start={start}
            end={end}
            logoUrl={getIcon('light')}
            showProgress={contentType === 'live'}
          />
        </div>
        <div className="ShakaPlayerUiHeaderTitles">
          <Typography bold variant="h3">
            {title}
          </Typography>
          {sub_title && <Typography variant="h4">{sub_title}</Typography>}
        </div>
      </Breadcrumb>
    </div>
  );
}

export default ShakaPlayerUiHeader;
