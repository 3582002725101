import React from 'react';
import { useVodPortal as useVodPortalBase } from '@oqee/core';

const HANDLED_SECTION_TYPES = [
  'contents',
  'group_banner',
  'groups',
  'head',
  'grid_banner',
  'contents_landscape',
  'content_banner'
];
const HANDLED_ENTRY_TYPES = ['group', 'portal', 'vod', 'grid'];

function filter({ lines, ...rest }) {
  return {
    ...rest,
    lines: lines
      .filter(line => HANDLED_SECTION_TYPES.includes(line.type))
      .map(line => ({
        ...line,
        entries: line.entries.filter(entry => HANDLED_ENTRY_TYPES.includes(entry.type))
      }))
      .filter(line => line.entries.length)
  };
}

function useVodPortal(vodPortalId) {
  const rawVodPortal = useVodPortalBase(vodPortalId);

  const vodPortal = React.useMemo(() => rawVodPortal && filter(rawVodPortal), [rawVodPortal]);
  return vodPortal;
}

export default useVodPortal;
