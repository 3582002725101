import React from 'react';
import clsx from 'clsx';
import { Typography } from '@oqee/ui';
import { PICTURE_FORMAT_WEB, formatPictureUrl, useVodOffersContext } from '@oqee/core';
import { APIVodOffer } from '@oqee/core/src/api/types/APIVodOffer';
import { APIVodProvider } from '@oqee/core/src/api/types/APIVodProvider';
import { useParams } from 'react-router-dom';

import { useWebStoreState } from '../../../store/webStoreUtils';

import Spinner from '../../shared/Spinner/Spinner';

import './SvodProviders.css';

type SvodStepPageParams = {
  portalId: string;
};

interface SvodProviderProps {
  offer: APIVodOffer;
  keyItem: number;
}

function SvodProviders({ offer, keyItem }: SvodProviderProps) {
  const params = useParams() as SvodStepPageParams;
  const { VOD_PROVIDER_LOGO } = PICTURE_FORMAT_WEB;
  const { selectedOffer, onSelectOffer } = useVodOffersContext();
  const providers: { [key: string]: APIVodProvider } = useWebStoreState(
    state => state.channel.servicePlan.vod_providers
  );

  const svodProvider: APIVodProvider | undefined = Object.values(providers).find(provider => {
    // from vod/portal/
    if (params.portalId) {
      return provider.portal_id === Number(params.portalId);
    }
    // from home/vod/
    return provider.id === offer.provider_id;
  });

  return svodProvider ? (
    <div
      onClick={() => onSelectOffer({ key: keyItem, svodProvider })}
      className={clsx('VodProvidersElementList', { checked: selectedOffer.key === keyItem })}
      key={svodProvider?.id}
    >
      <div className="VodProvidersElement">
        <div className="VodProvidersElementContainer">
          <img className="Logo" src={formatPictureUrl(svodProvider.logo_dark, VOD_PROVIDER_LOGO)} alt="" />
          <Typography variant="body1" bold>
            {svodProvider.name}
          </Typography>
        </div>
        <img src={`/img/checkbox_${selectedOffer.key === keyItem ? 'checked' : 'unchecked'}.svg`} alt="" />
      </div>
    </div>
  ) : (
    <Spinner testId="svodProvider-pending" />
  );
}

export default SvodProviders;
