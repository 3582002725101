import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useNavigationState from '../../../hooks/useNavigationState';
import useScrollRestoration from '../../../hooks/useScrollRestoration';

import './PageLayout.css';

function PageLayout({ children }) {
  const { pathname } = useLocation();
  const { saveWindowScrollY, lastLocation, navigationState } = useNavigationState();
  const { restoreWindowScrollPosition } = useScrollRestoration();

  useEffect(() => {
    if (
      pathname !== lastLocation?.pathname &&
      !navigationState?.rowId &&
      !navigationState?.entryId &&
      navigationState.scrollY
    ) {
      restoreWindowScrollPosition(false);
    } else {
      restoreWindowScrollPosition(true);
    }

    return () => {
      if (
        (pathname === lastLocation?.pathname || !lastLocation) &&
        (!navigationState?.rowId || !navigationState?.entryId) &&
        navigationState.scrollY
      ) {
        saveWindowScrollY();
      }
    };
  }, []);

  return <div className="PageLayout">{children}</div>;
}

export default PageLayout;
