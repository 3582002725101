import React from 'react';
import { CONSTANTS } from '@oqee/core';

import { HeaderNav } from '../../utils/webUtils';
import VodPortalPage from './VodPortalPage';

function VodOqeeCinePage() {
  return <VodPortalPage activeHeaderNav={HeaderNav.OQEE_CINE} forcePortalId={CONSTANTS.OQEE_CINE_PORTAL_ID} />;
}

export default VodOqeeCinePage;
