import React from 'react';
import { useParams } from 'react-router-dom';
import { useReplayPortal, useReplayPortalItem, CONSTANTS } from '@oqee/core';

import MainLayout from '../../../components/layout/MainLayout';
import ContentRows, { CONTENT_ROWS_PLACEHOLDERS } from '../../../components/content/ContentRows';
import NavHeader from '../../../components/layout/NavHeader';
import { HeaderNav } from '../../../utils/webUtils';
import ParentalGuard from '../../../components/shared/ParentalGuard';

import './ReplayPortalPage.css';

type ReplayPortalPageParams = {
  strPortalId: string;
};

function ReplayPortalPage() {
  const { strPortalId } = useParams<ReplayPortalPageParams>();

  const portalId = Number(strPortalId);
  const { isReady, portal: storePortal } = useReplayPortal(portalId, null, CONSTANTS.MAIN_HOME_ID);

  const portal = useReplayPortalItem(storePortal);

  return (
    <MainLayout activeHeaderNav={HeaderNav.REPLAY} fbScreenName={`replay_portal_${strPortalId}`}>
      <div className="ReplayPortalPage">
        {isReady ? (
          <ParentalGuard isAdult={!!storePortal?.need_parental_code}>
            <NavHeader title={portal?.name} />
            <ContentRows rows={portal.getSections()} />
          </ParentalGuard>
        ) : (
          <ContentRows rows={CONTENT_ROWS_PLACEHOLDERS} />
        )}
      </div>
    </MainLayout>
  );
}

export default ReplayPortalPage;
