import React from 'react';
import clsx from 'clsx';
import { APIVodOffer, APIVodOfferType } from '@oqee/core/src/api/types/APIVodOffer';
import { APIVodProvider } from '@oqee/core/src/api/types/APIVodProvider';
import { formatPrice, useVodOffersContext } from '@oqee/core';
import { Typography } from '@oqee/ui';

import { useWebStoreState } from '../../store/webStoreUtils';

import VodOffersProviderInfo from './VodOffersProviderInfo';

import './VodOffersType.css';

type OfferType = {
  text: string;
  type: string;
  price: number | false;
};

function VodProviderPrice() {
  const { selectedOffer, onSelectOffer, offerListType } = useVodOffersContext();
  const providers: { [key: string]: APIVodProvider } = useWebStoreState(
    state => state.channel.servicePlan.vod_providers
  );

  const calcMinPrice = React.useCallback((offersType: APIVodOffer[]) => {
    return offersType && offersType.length > 0 && Math.min(...offersType.map(offerType => offerType.price));
  }, []);

  const listItemSvod: APIVodProvider | undefined = React.useMemo(
    () => Object.values(providers).find(provider => provider.id === offerListType.SVOD?.[0]?.provider_id),
    [providers, offerListType.SVOD]
  );

  const minEstPrice = React.useMemo(() => calcMinPrice(offerListType.EST || []), []);
  const minTvodPrice = React.useMemo(() => calcMinPrice(offerListType.TVOD || []), []);
  const minSvodPrice = React.useMemo(() => (listItemSvod && listItemSvod.subscription_price) || 0, []);

  const offers: OfferType[] = [
    {
      text: 'Acheter',
      type: 'EST',
      price: minEstPrice
    },
    {
      text: 'Louer',
      type: 'TVOD',
      price: minTvodPrice
    },
    {
      text: `S'abonner`,
      type: 'SVOD',
      price: minSvodPrice
    }
  ].filter(offer => offer.price);

  React.useEffect(() => {
    if (!selectedOffer.type || selectedOffer.key === 0) {
      offers && onSelectOffer({ key: 0, type: offerListType[`${offers[0].type}`][0].type });
    } else {
      onSelectOffer({ key: selectedOffer.key, type: selectedOffer.type });
    }
  }, [selectedOffer.type]);

  return (
    <div className="VodOffersTypeContent">
      <div className="VodOffersTypeContentLeft">
        <div className="VodOffersTypeList">
          {offers.map((offer: OfferType, key: number) => (
            <div
              className={clsx('VodOffersTypeListElement', { checked: selectedOffer.key === key })}
              key={`${key}_${offer.type}`}
              onClick={() => onSelectOffer({ key, type: offer.type as APIVodOfferType })}
            >
              <div className="VodOffersTypeListType">
                <Typography variant="body1" bold>
                  {offer.text}
                </Typography>
                <img alt="" src={`/img/checkbox_${selectedOffer.key === key ? 'checked' : 'unchecked'}.svg`} />
              </div>
              <div className="VodOffersTypeListDetailsPrice">
                <Typography variant="body2">À partir de {formatPrice(offer.price, '€')}</Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
      <VodOffersProviderInfo />
    </div>
  );
}

export default VodProviderPrice;
