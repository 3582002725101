import React from 'react';
import clsx from 'clsx';
import { APIUserAvatarAgeRange, formatPictureUrl, useAccountProfile } from '@oqee/core';
import { useParams, NavigateFunction, useNavigate, useLocation, Location } from 'react-router-dom';

import Typography from '../../../../components/shared/Typography';
import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import Button from '../../../../components/shared/Button';
import NavHeader from '../../../../components/layout/NavHeader';

import './ProfileAge.css';

const UNKNOWN_AGE_KEY = 'unknown';

interface ProfileAgeItem {
  ageRange: APIUserAvatarAgeRange;
  selected: boolean;
  onSelect: () => void;
}

function ProfileAgeItem({ ageRange, selected, onSelect }) {
  const pictureUrl: string = formatPictureUrl(ageRange.picture, '');

  return (
    <div className={clsx('ProfileAgeItem', { selected })} onClick={onSelect}>
      <img src={pictureUrl} alt="" />
      <span className="ProfileAgeItemRange">
        <Typography variant="body1" bold>
          {ageRange.display}
        </Typography>
      </span>
    </div>
  );
}

type ProfileAgeParams = {
  profileId: string | undefined;
};

function ProfileAge() {
  const { profileId } = useParams() as ProfileAgeParams;
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { avatars, getProfile } = useAccountProfile();

  const isOnboarding: boolean = location.pathname.includes('/onboarding');
  const isProfileCreation: boolean = profileId === undefined;
  const state: any = location.state ?? {};
  const currentProfile = getProfile(profileId);

  const initialAgeRangeKey: string = state.ageRange ?? currentProfile?.age_range ?? UNKNOWN_AGE_KEY;
  const [selectedAgeRangeKey, setSelectedAgeRangeKey] = React.useState<string>(initialAgeRangeKey);

  const handleBack = isOnboarding ? undefined : () => navigate(isProfileCreation ? '../..' : '..');

  const handleContinue = () => navigate(selectedAgeRangeKey);

  const handleSkip = () => navigate(UNKNOWN_AGE_KEY);

  return (
    <UserProfileLayout>
      <div className="ProfileAge">
        <NavHeader onBack={handleBack} onSkip={handleSkip} />
        <div className="ProfileAgeTitle">
          <Typography variant="h1">Âge du profil</Typography>
        </div>
        <div className="ProfileAgeDescription">
          <Typography variant="h4">
            Selon l&apos;âge vous aurez accès à une sélection de programmes personnalisés
          </Typography>
        </div>
        <div className="ProfileAgeList">
          {avatars.age_ranges
            .filter(ageRange => ageRange.key !== UNKNOWN_AGE_KEY)
            .map((ageRange, index) => (
              <ProfileAgeItem
                key={index}
                ageRange={ageRange}
                selected={ageRange.key === selectedAgeRangeKey}
                onSelect={() => setSelectedAgeRangeKey(ageRange.key)}
              />
            ))}
        </div>
        <div className="ProfileAgeContinueButton">
          <Button label="Continuer" type="primary" onClick={handleContinue} />
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default ProfileAge;
