import React from 'react';
import { useNewsletter } from '@oqee/core';
import { useNavigate } from 'react-router-dom';

import { HeaderNav } from '../../../utils/webUtils';
import Typography from '../../../components/shared/Typography';
import MainLayout from '../../../components/layout/MainLayout';
import NavHeader from '../../../components/layout/NavHeader';
import Button from '../../../components/shared/Button';
import Toggle from '../../../components/shared/Toggle';

import './SettingsNewsletter.css';

function SettingsNewsletter() {
  const navigate = useNavigate();
  const { isSubscribed, setSubscription } = useNewsletter();
  const [value, setValue] = React.useState(isSubscribed ?? false);

  const toggleCheck = () => setValue(checked => !checked);

  const handleBack = () => navigate('/settings');

  const handleConfirm = () => {
    setSubscription(value);
    navigate('/settings');
  };

  return (
    <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>
      <div className="SettingsNewsletter">
        <NavHeader onBack={handleBack} />
        <div className="SettingsNewsletterContent">
          <div className="SettingsNewsletterTitle">
            <Typography variant="h2">Communication</Typography>
          </div>

          <div className="SettingsNewsletterMailbox">
            <img src="/img/newsletter-mailbox.svg" width="226" height="181" alt="" />
          </div>
          <div className="SettingsNewsletterDescription">
            <Typography variant="body1">
              Pour recevoir par email : nos nouveautés, enquêtes et offres commerciales.
            </Typography>
          </div>
          <Toggle label="Newsletter" value={value} onToggle={toggleCheck} />

          <div className="SettingsNewsletterConfirmButton">
            <Button label="Confirmer" type="primary" onClick={handleConfirm} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default SettingsNewsletter;
