import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserProfile from './profile/UserProfile';
import SettingsHomePage from './SettingsHomePage';
import SettingsNewsletter from './SettingsNewsletter';
import SettingsPurchaseCode from './SettingsPurchaseCode';
import SettingsSubscriptions from './SettingsSubscriptions';
import NpvrUsage from './NpvrUsage';

function SettingsRoute() {
  return (
    <Routes>
      <Route path="profile/*" element={<UserProfile />} />
      <Route path="subscriptions/*" element={<SettingsSubscriptions />} />
      <Route path="purchase_code" element={<SettingsPurchaseCode />} />
      <Route path="newsletter" element={<SettingsNewsletter />} />
      <Route path="npvr_usage/*" element={<NpvrUsage />} />
      <Route index element={<SettingsHomePage />} />
    </Routes>
  );
}

export default SettingsRoute;
